import { COLORS, IMAGES, FONTS} from "../../../constants/index";
import { StyleSheet, View, Text, Dimensions, TouchableOpacity, Image, FlatList, Modal} from "react-native";
import React from "react";
import { CheckCircle2 } from 'lucide-react';
import Layouts from "../../Layouts/ScreenView";
import Button from "../../../components/Button";
import { useCart } from "../../../context/SinglecartContext";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import { useGroupCart } from "../../../context/GroupmanagerContext";

function SuccessScreen({ navigation, route, layoutState }) {
  const { groupno, memberId } = route.params || {};
  const { merchantData } =  route.params || {};
  const { dispatch } = useCart();
  const {clearCart, state} = useGroupCart();
  
  const generalClearCart = () => {
    if(groupno && memberId ) {
      clearCart(groupno);
    }else{
      dispatch({ type: 'CLEAR_CART', payload: groupno });
    }
  }

  const {totalAmountPaid, brandname, billStatus, paymentOptionsPlan } = route.params;

  const navigateToOffers = () => {
    generalClearCart();
    navigation.navigate("OfferScreen", { merchantData });
  }

  const handleBillViewAMount = () => {
    navigation.navigate("RecieptScreen", {showPersonaStatus: true});
  }

  return (
    <Layouts>
        <View style={styles.container}>
            <View style={styles.topStatus}>
             <CheckCircle2 size={70} color="green"/>
             <Text style={{...FONTS.smallfineText, fontSize: 14, paddingVertical: 10, textAlign: "center", lineHeight: 20}}>You have succesfully placed an order (Pay On Deliverly) Ksh. {totalAmountPaid} to {brandname}.</Text>
             <Text style={{...FONTS.smallfineText, fontSize: 14, paddingVertical: 10, textAlign: "center", lineHeight: 20, color: COLORS.blue}}>Order Status {paymentOptionsPlan && paymentOptionsPlan}</Text>
            </View>
            {billStatus ?
              <View style={styles.footerSections}>
                <Button text="Done" onPress={navigateToOffers} style={styles.buttonFooters}/>
                <Button text="View Bill Status" onPress={handleBillViewAMount} style={styles.buttonFooters} />
            </View>
            :
             <View style={styles.footerSection}>
                <Button text="Done" onPress={navigateToOffers} style={styles.buttonFooter}/>
                <Text style={{...FONTS.smallfineText, color: "skyblue", fontSize: 14, paddingVertical: 10}}>Download receipt</Text>
            </View>
            }
        </View>
    </Layouts>
  );
}

const { width, height } = Dimensions.get("screen")
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.backgroundDark,
    position: "relative",
    maxWidth: 430,
    position: "relative",
  },
  topStatus: {
    justifyContent: "space-around",
    alignItems: "center",
    position: "absolute", 
    top: 200,
    width: "60%",
  },
  footerSection: {
    width: "100%",
    position: "absolute", 
    bottom: 40,
    right: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonFooter: {
    width: "60%",
  },
  footerSections: {
    width: "100%",
    position: "absolute", 
    bottom: 40,
    right: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  buttonFooters: {
    width: "40%",
    marginRight: 5,
    fontSize: 11,
  }
});
export default SuccessScreen;
