import React, { useEffect } from 'react';
import { View, Text } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';

const PaymentResponse = () => {
  const navigation = useNavigation();
  const route = useRoute();

  useEffect(() => {
    const { response } = route.params || {};
    if (response) {
      try {
        const decodedResponse = atob(response);
        const responseJson = JSON.parse(decodedResponse);

        if (responseJson.errors && responseJson.errors.length > 0) {
          console.error('Payment Error:', responseJson.errors[0].message);
          navigation.navigate('Home', { status: 'error' });
        } else {
          console.log('Payment Success:', responseJson);
          navigation.navigate('Home', { status: 'success' });
        }
      } catch (error) {
        console.error('Error processing payment response:', error);
        navigation.navigate('Home', { status: 'error' });
      }
    }
  }, [route.params]);

  return (
    <View>
      <Text>Processing payment response...</Text>
    </View>
  );
};

export default PaymentResponse;
