import { StyleSheet, View, Dimensions, TextInput } from "react-native";
import { COLORS } from "../constants";
import { Search } from "lucide-react";

const SearchBar = ({ searchTerm, onChangeText }) => {
  return (
    <View style={styles.inputwrapper}>
      <Search size={20} color={COLORS.white} />
      <TextInput
        placeholder="Search"
        placeholderTextColor={COLORS.white}
        value={searchTerm}
        onChangeText={onChangeText}
        style={styles.input}
      />
    </View>
  );
};

export default SearchBar;

const { width, height } = Dimensions.get("screen");
const styles = StyleSheet.create({
  inputwrapper: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: COLORS.backgroundLight,
    borderRadius: 10,
    paddingHorizontal: 5,
    paddingVertical: 5,
    width: "98%",
  },
  input: {
    height: (49.43 * height) / 930,
    paddingHorizontal: 10,
    paddingVertical: 10,
    color: COLORS.white,
    marginLeft: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderColor: "none",
    flex: 1,
  },
});
