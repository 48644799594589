import { COLORS, IMAGES, FONTS} from "../../../../constants/index";
import { StyleSheet, View, TextInput, Text, Picker} from "react-native";
import React, { useState } from "react";
import { ChevronDown, CheckCircle2} from 'lucide-react';
import { TouchableOpacity } from "react-native-web";

function Forms({route, id, modifyTableCapacity, count,           setFullName, fullName,
    userEmail, setUseEmail,
    userPhoneNumber, setUserPhoneNo,
    seatingPosition, setSeatingPosition,
    tableTime, setTableTime, 
    numberOfGuests, setNumberOfGuests, 
    pickupTime, setPickUpTime,
    userAddress, setUserAddress
}) {
  
  const generateTimeOptions = () => {
    const options = [];
    for (let i = 0; i < 24; i++) {
      const hour = i < 10 ? `0${i}` : `${i}`;
      options.push(`${hour}:00`);
    }
    return options;
  };
  const timeOptions = generateTimeOptions();


  return (
    <>
    {
        (id === "Dine In" || id === "Book Table" || id === "Pickup" || id === "Delivery" || id==="")?  
        <View style={styles.inputs}>
            <TextInput placeholder="Enter your name" style={styles.paymentsInputs} value={fullName} onChangeText={(name) => setFullName(name)}/>
            <TextInput placeholder="Enter your email" style={styles.paymentsInputs} value={userEmail} onChangeText={(email) => setUseEmail(email)}/>
            <TextInput placeholder="Enter your Phonenumber" style={styles.paymentsInputs} value={userPhoneNumber} onChangeText={(phone) => setUserPhoneNo(phone)}/>

            {/* Table */}
            {
                id === "Book Table" && <>
                <View style={styles.cutomizeFlexOne}>
                    <TextInput placeholder="Select sitting position" style={styles.cutomizeInput} value={seatingPosition} onChangeText={(seating) => setSeatingPosition(seating)}/>
                    <ChevronDown size={18} color="gray" />
                </View>
               <View style={styles.tableFlex}>
                <View style={styles.cutomizeFlex}>
                    <TextInput placeholder="Time" value={tableTime} style={styles.cutomizeInput} onChangeText={(time) => setTableTime(time)}/>
                    <Picker
                    style={{backgroundColor: "transparent", border: "none", color: "gray"}}
                    onValueChange={(itemValue, itemIndex) => setTableTime(itemValue)}
                    >
                        {timeOptions.map((time, index) => (
                        <Picker.Item key={index} label={time} value={time}/>
                        ))}
                    </Picker>
                </View>
                <View style={styles.cutomizeFlex}>
                    <TextInput placeholder="Guest" style={styles.cutomizeInput} value={numberOfGuests} onChangeText={(guests) => setNumberOfGuests(guests)}/>
                    {/* <View style={styles.tableFlexBottom}>
                        <TouchableOpacity style={styles.btns} onPress={() => modifyTableCapacity("-")}><Text style={styles.actions}>-</Text></TouchableOpacity>
                        <Text style={styles.outputs}>{count}</Text>
                        <TouchableOpacity style={styles.btns} onPress={() => modifyTableCapacity("+")}><Text style={styles.actions}>+</Text></TouchableOpacity>
                    </View> */}
                </View>
               </View>
            </>
            }

            {
                id === "Delivery" && <TextInput placeholder="Address" style={styles.paymentsInputs} value={userAddress} onChangeText={(Address) => setUserAddress(Address)}/>
            }
            
            {
                id === "Pickup" &&  <View style={styles.cutomizeFlexOne}>
                    <TextInput placeholder="Time" value={pickupTime} style={styles.cutomizeInput} onChangeText={(picktime) => setPickUpTime(picktime)}/>
                    <Picker
                    style={{backgroundColor: "transparent", border: "none", color: "gray"}}
                    onValueChange={(itemValue, itemIndex) => setPickUpTime(itemValue)}
                    >
                        {timeOptions.map((time, index) => (
                        <Picker.Item key={index} label={time} value={time}/>
                        ))}
                    </Picker>
                </View>
            }
            {
                (id === "Book Table" || id === "Pickup" || id === "Delivery" ) && <>
                <View style={styles.whatsappOrder}>
                    <TouchableOpacity style={styles.whatsapp}>
                      <CheckCircle2 size={18} color="green"/>
                    </TouchableOpacity>
                    <View style={{marginLeft: 10}}>
                        <Text style={{...FONTS.smallfineText, fontSize: 10, fontWeight: 700}}>WhatsApp order</Text>
                        <Text style={{...FONTS.smallfineText, fontSize: 10, color: COLORS.gray}}>Send order to our whatsApp</Text>
                    </View>
                </View>
                </>
            }
        </View> :
        <View style={styles.selections}>
            <Text style={{...FONTS.smallfineText, fontSize: 11, textAlign: "center"}}>Select the buttons above to customize much on the preference of payment of the products.</Text>
        </View>
    }
       
    </>
  );
}

const styles = StyleSheet.create({
    whatsappOrder: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        marginVertical: 5,
        color: COLORS.gray,
        border: "none",
        width: "100%",
        marginTop: 8,
    },
    inputs: {
        marginTop: 5,
        maxWidth: 430,
    },
    paymentsInputs: {
        padding: 10,
        borderRadius: 10,
        backgroundColor: COLORS.backgroundLight,
        marginVertical: 4,
        color: COLORS.gray,
        border: "none",
        width: "100%",
    },
    tableFlex: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
    },
    cutomize: {
        width: "49%",
        padding: 10,
        borderRadius: 10,
        backgroundColor: COLORS.backgroundLight,
        marginVertical: 4,
        color: COLORS.gray,
        border: "none",
    },
    cutomizeFlex: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 10,
        borderRadius: 10,
        backgroundColor: COLORS.backgroundLight,
        marginVertical: 4,
        color: COLORS.gray,
        border: "none",
        width: "49%",
    },
    tableFlexBottom: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    cutomizeInput: {
        width: "49%",
        borderRadius: 10,
        border: "none",
        color: COLORS.gray,
    },
    btns: {
        width: 15,
        height: 15,
        borderRadius: 100,
        borderWidth: 1,
        borderColor: COLORS.white,
        justifyContent: "center",
        alignItems: "center",
    },
    outputs: {
        ...FONTS.smallfineText,
        fontSize: 14,
        marginHorizontal: 5,
    },
    actions: {
        ...FONTS.smallfineText,
        fontSize: 14,
    },
    cutomizeFlexOne: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 10,
        borderRadius: 10,
        backgroundColor: COLORS.backgroundLight,
        marginVertical: 4,
        color: COLORS.gray,
        border: "none",
        width: "100%",
    },
    selections: {
        width: "100%",
        paddingVertical: 5,
        paddingHorizontal: 5,
        borderRadius: 10,
        backgroundColor: COLORS.green,
        marginVertical: 10,
        justifyContent: "center",
        alignItems: "center",
    }
});
export default Forms;
