import { createContext, useContext, useReducer, useState, useEffect } from 'react';

const GroupCartContext = createContext();

const initialState = {
  group: [],
};

const cartReducer = (state, action) => {
  switch (action.type) {

    case 'CREATE_GROUP':
      const newGroup = {
        id: action.payload,
        members: [],
      };
      return {
        ...state,
        group: [...state.group, newGroup],
      };
      
    case 'JOIN_GROUP':
      const { groupId, member } = action.payload;
      const groupIndex = state.group.findIndex(group => group.id === groupId);
      if (groupIndex !== -1) {
        const updatedGroup = { ...state.group[groupIndex] };
        const unifiedName = Object.values(member).join('');
        updatedGroup.members.push({ name: unifiedName, items: [] });
        return {
          ...state,
          group: [
            ...state.group.slice(0, groupIndex),
            updatedGroup,
            ...state.group.slice(groupIndex + 1),
          ],
        };
      }
      return state;

    
      case 'ADD_ITEMS_TO_MEMBER':
        const { groupno, memberId, item } = action.payload;
        const groupIndex2 = state.group.findIndex(group => group.id === groupno);
        if (groupIndex2 !== -1) {
          const updatedGroup = { ...state.group[groupIndex2] };
          const memberIndex = updatedGroup.members.findIndex(
            member => member.name === memberId
          );
          if (memberIndex !== -1) {
            const updatedMember = { ...updatedGroup.members[memberIndex] };
            const itemsArray = Array.isArray(item) ? item : [item];
            updatedMember.items = [...updatedMember.items, ...itemsArray];
            return {
              ...state,
              group: [
                ...state.group.slice(0, groupIndex2),
                {
                  ...updatedGroup,
                  members: [
                    ...updatedGroup.members.slice(0, memberIndex),
                    updatedMember,
                    ...updatedGroup.members.slice(memberIndex + 1),
                  ],
                },
                ...state.group.slice(groupIndex2 + 1),
              ],
            };
          }
        }
        return state;

        case 'REMOVE_FROM_CART':
          const { groupidremove, memberidToRemove, itemtoremove } = action.payload;
          const removeGroupIndex = state.group.findIndex(group => group.id === groupidremove);
          if (removeGroupIndex !== -1) {
            const updatedRemoveGroup = { ...state.group[removeGroupIndex] };
            const removeMemberIndex = updatedRemoveGroup.members.findIndex(
              member => member.name === memberidToRemove
            );
            if (removeMemberIndex !== -1) {
              const updatedRemoveMember = { ...updatedRemoveGroup.members[removeMemberIndex] };
              updatedRemoveMember.items = updatedRemoveMember.items.filter(item => item.id !== itemtoremove.id);
              return {
                ...state,
                group: [
                  ...state.group.slice(0, removeGroupIndex),
                  {
                    ...updatedRemoveGroup,
                    members: [
                      ...updatedRemoveGroup.members.slice(0, removeMemberIndex),
                      updatedRemoveMember,
                      ...updatedRemoveGroup.members.slice(removeMemberIndex + 1),
                    ],
                  },
                  ...state.group.slice(removeGroupIndex + 1),
                ],
              };
            }
          }
          return state;

      case 'LEAVE_GROUP':
        const { groupidtoleave, memberidleaving} = action.payload;
        const leaveGroupIndex = state.group.findIndex(group => group.id === groupidtoleave);
        if (leaveGroupIndex !== -1) {
          const updatedLeaveGroup = { ...state.group[leaveGroupIndex] };
          updatedLeaveGroup.members = updatedLeaveGroup.members.filter(
            member => member.name !== memberidleaving
          );
          return {
            ...state,
            group: [
              ...state.group.slice(0, leaveGroupIndex),
              updatedLeaveGroup,
              ...state.group.slice(leaveGroupIndex + 1),
            ],
          };
        }
        return state;
  
    default:
      return state;
  }
};



const GroupCartProvider = ({ children }) => {
  const storedState = JSON.parse(localStorage.getItem('groupCartState')) || initialState;
  const [state, dispatch] = useReducer(cartReducer, storedState);
  console.log('Items added to cart', state)

  useEffect(() => {
    localStorage.setItem('groupCartState', JSON.stringify(state));
  }, [state]);


  const createGroup = (groupId) => {
    dispatch({ type: 'CREATE_GROUP', payload: groupId });
  };

  const joinGroup = (groupId, member) => {
    dispatch({ type: 'JOIN_GROUP', payload: { groupId, member } });
  };

  const leaveGroup = (groupidtoleave, memberidleaving) => {
    dispatch({ type: 'LEAVE_GROUP', payload: { groupidtoleave, memberidleaving } });
  };

  const addToCart = (groupno, memberId, item) => {
    dispatch({ type: 'ADD_ITEMS_TO_MEMBER', payload: { groupno, memberId, item } });
  };

  const removeFromCart = (groupidremove, memberidToRemove, itemtoremove) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: { groupidremove, memberidToRemove, itemtoremove } });
  };

  const clearCart = (groupId) => {
    dispatch({ type: 'CLEAR_CART', payload: { groupId } });
  };


  const calculateTotalAmount = (items) => {
    return items.reduce((total, item) => total + (item.price * item.quantity), 0);
  };
  const getNumberOfItems = (items) => {
    return items.length;
  };
  const getPriceOfItem = (state, groupId, memberId, itemId) => {
    const group = state.group.find(group => group.id === groupId);
    if (group) {
      const member = group.members.find(member => member.name === memberId);
      if (member) {
        const item = member.items.find(item => item.id === itemId);
        return item ? item.price : null;
      }
    }
    return null;
  };
  const getitemQuantityPerEach = (state, groupId, memberId, itemId) => {
    const group = state.group.find(group => group.id === groupId);
    if (group) {
      const member = group.members.find(member => member.name === memberId);
      if (member) {
        const item = member.items.find(item => item.id === itemId);
        return item ? item.quantity : 0;
      }
    }
    return 0;
  };
  const calculateIndividualTotal = (state, groupId, memberId) => {
    const group = state.group.find(group => group.id === groupId);
    if (group) {
      const member = group.members.find(member => member.name === memberId);
      return member ? calculateTotalAmount(member.items) : 0;
    }
    return 0;
  };
  const calculateTotalAmountPerIndividual = (state, groupId) => {
    const group = state.group.find(group => group.id === groupId);
    return group ? group.members.map(member => ({
      memberId: member.name,
      totalAmount: calculateIndividualTotal(state, groupId, member.name),
    })) : [];
  };
  const getLengthOfItemsPerIndividual = (state, groupId) => {
    const group = state.group.find(group => group.id === groupId);
    return group ? group.members.map(member => ({
      memberId: member.name,
      itemCount: getNumberOfItems(member.items),
    })) : [];
  };
  const getAllMembers = (state, groupId) => {
    const group = state.group.find(group => group.id === groupId);
    return group ? group.members.length : 0;
  };
  const getAllMembersNames = (state, groupId) => {
    const group = state.group.find(group => group.id === groupId);
    return group ? group.members.map(member => member.name) : [];
  };
  const getGroupIdOrName = (group) => {
    return group.id;
  };
  const getProductsPerMember = (state, groupId, memberId) => {
    const group = state.group.find(group => group.id === groupId);
    if (group) {
      const member = group.members.find(member => member.name === memberId);
      return member ? member.items : [];
    }
    return [];
  };
  const getFirstGroupName = () => {
    const firstGroup = state.group[0];
    return firstGroup ? firstGroup.name : null;
  };
  const getFirstGroupAndMember = () => {
    const firstGroupName = getFirstGroupName();
    if (firstGroupName) {
      const firstGroup = state.group.find(group => group.name === firstGroupName);
      const firstMember = firstGroup ? firstGroup.members[0] : null;
      return { group: firstGroup, member: firstMember };
    }
    return { group: null, member: null };
  };

return (
    <GroupCartContext.Provider
      value={{
        // States
        state,
        dispatch,

        // Group functions >> Setters and getters
        calculateTotalAmount,
        getNumberOfItems,
        getPriceOfItem,
        calculateIndividualTotal,
        calculateTotalAmountPerIndividual,
        getLengthOfItemsPerIndividual,
        getAllMembers,
        getGroupIdOrName,
        getitemQuantityPerEach,
        getNumberOfItems,
        getAllMembersNames,
        getProductsPerMember,
        getFirstGroupAndMember,

        // Reducers
        createGroup,
        joinGroup,
        leaveGroup,
        addToCart,
        removeFromCart,
        clearCart,
      }}
    >
    {children}
    </GroupCartContext.Provider>
);
};

const useGroupCart = () => {
  const context = useContext(GroupCartContext);
  if (!context) {
      throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

export { GroupCartProvider, useGroupCart };
