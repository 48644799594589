import { COLORS, IMAGES, FONTS} from "../../../constants/index";
import { StyleSheet, View, Text, Dimensions, TouchableOpacity, Image, FlatList, Modal, TextInput} from "react-native";
import React, { useState, useEffect } from "react";
import { ArrowLeft, ChevronDown, Users2, ChevronUp } from 'lucide-react';
import Button from "../../../components/Button";
import { useCart } from "../../../context/SinglecartContext";
import LinearGradient from 'react-native-web-linear-gradient';
import Layouts from "../../Layouts/ScreenView";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import { ScrollView } from "react-native-web";
import { useGroupCart } from "../../../context/GroupmanagerContext";
import { X } from 'lucide-react';
import SingleGroupCartProduct from "./components/GroupCartProduct";

function CartScreen({ navigation, route }) {
  const { getNumberOfItems, groupId} = route.params;

  const [memberId, setMemberId] = useState("");
  const [memberPhone, setMemberPhone] = useState("");
  const [group, setGroup] = useState(null);
  const [member, setMember] = useState(null);

  const {getFirstGroupAndMember, joinGroup, state, getAllMembers, getAllMembersNames, getProductsPerMember, getGroupAndMember} = useGroupCart();
  useEffect(() => {
    const { group, member } = getFirstGroupAndMember();
    setGroup(group);
    setMember(member);
  }, []);

  const navigateBackToAddProducts = async () => {
    try{
      await navigation.navigate("OfferScreen", {"groupno": group?group:groupId, "memberId": member?member:memberId})
    }catch(error) {
      console.log(error)
    }
  }

  const { calculateTotalAmount, totalCart } = useCart();
  const ratings = parseFloat((calculateTotalAmount() - (calculateTotalAmount() - (calculateTotalAmount() * 0.116))).toFixed(2))

  const {stateLayout} = useCart();

  const navigateToPayBills = async (product) => {
    product && navigation.navigate("RecieptScreen", {getNumberOfItems, billStatus: true});
   };

  const [joingroup, setJoinGroup] = useState(false)

  const groupMembersProfiles = getAllMembersNames(state, groupId);

  const joinPersonGroup = async (groupId, memberId) => {
    if(groupId && memberId){
      try{
        await joinGroup(groupId, memberId)
        setJoinGroup(false)
      }catch(error){
        console.error(error);
      }
    }
    alert(`You've joined ${group} group, Begin a new journey of sharing bills, group cart interractions and payments.`)
  }

  const closeTheModal = () => setJoinGroup(false)
  const [seePerson, setSeePerson] = useState(null);
  const singlePersonViewGroupCart = (nameofPerson) => {
    setSeePerson((prevName) => (prevName === nameofPerson ? null : nameofPerson));
  };

  return (
    <Layouts>
      <View style={styles.container}>
        <View style={styles.navigationLinks}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
          <ArrowLeft color="white" size={18}/>
          </TouchableOpacity>
        </View>
          
        <View style={styles.offerViewPage}>
          <View  style={styles.topImageView}>
            <View style={styles.imagecover}>
              <Image source={IMAGES.barnextdoorbackground} style={styles.imageset}/>
            </View>
            <LinearGradient 
                colors={[
                'rgba(30, 32, 46, 1.1)',
                'rgba(30, 32, 46, 1.0)',
                'rgba(30, 32, 46, .95)',
                'rgba(30, 32, 46, .80)',
                'rgba(30, 32, 46, .70)',
                'rgba(30, 32, 46, .60)',
                'rgba(30, 32, 46, .50)',
                'rgba(30, 32, 46, .40)',
                'rgba(30, 32, 46, .30)',
                'rgba(30, 32, 46, .20)',
                'rgba(30, 32, 46, .10)',
                'rgba(30, 32, 46, .05)',
                'rgba(30, 32, 46, .04)',
                'rgba(30, 32, 46, .03)',
                'rgba(30, 32, 46, .02)',
                'rgba(30, 32, 46, .01)',
                'rgba(30, 32, 46, .00)',
              ]}
              start={{ x: 0, y: 1 }}
              end={{ x: 0, y: 0 }}
              angle={180}
              style={styles.imageZindexValues}
            >
              <View style={styles.avatarImageDisplay}><Image source={IMAGES.barnextdoor} style={styles.imageset}/></View>
                <View style={styles.textPreview}>
                  <Text style={[styles.title, { fontWeight: 700}]}>Bar Next Door</Text>
                  <Text style={styles.title}>Nairobi, Kenya</Text>
                </View>
            </LinearGradient>
          </View>

          <View style={styles.bottomContentView}>
           <View style={styles.groupProfileName}>
            <View style={styles.groupName}>
             <Users2 size={18} color="white"/>
             <Text style={{...FONTS.smallsubtext, fontSize: 14, marginLeft: 10}}>{group?group:groupId}'s Group</Text>
            </View>
            <View>
              <TouchableOpacity
              onPress={() => setJoinGroup(true)}
              style={{paddingHorizontal: 20, paddingVertical: 5, borderRadius: 100, backgroundColor: COLORS.blue}}
              ><Text style={{...FONTS.smallfineText,}}>Join</Text></TouchableOpacity>
            </View>
           </View>
            <View style={styles.topCartHeader}>
              <Text style={styles.cartHeading}>Members ({getAllMembers(state, groupId)})</Text>
              <Text style={styles.cartHeading}>Orders ({totalCart})</Text>
              <TouchableOpacity style={styles.addButtonCart} onPress={navigateBackToAddProducts}><Text style={{...FONTS.fineText, fontSize: 14}}>+ Add Items</Text></TouchableOpacity>
            </View>

            {/* Offers groups */}
            {groupMembersProfiles.length !== 0 ?
            <View>
              <ScrollView style={styles.allCartProductsDisplay}
              showsVerticalScrollIndicator={false}>
              {/* Personal cart display */}
              {groupMembersProfiles.length > 0 && [...new Set(groupMembersProfiles)].map((items, id) => {
                  return <TouchableOpacity style={styles.personalView} onPress={() => singlePersonViewGroupCart(items)} key={id}>
                    <View style={styles.topuserNmae}>
                    {seePerson === items ? <ChevronUp size={18} color="white" refX={0}/> : <ChevronDown size={18} color="white" refX={0}/> }
                    <Text style={{...FONTS.smallsubtext, fontSize: 13}}>{items}</Text>
                    </View>
                    {seePerson === items && 
                    <ScrollView style={styles.productsDisplay}
                      showsVerticalScrollIndicator={false}
                      showsHorizontalScrollIndicator={false}
                    >
                      {/* Items per person desplay */}
                      {
                        getProductsPerMember(state, groupId, items) && [...new Set(getProductsPerMember(state, groupId, items))].map((singleItem, id) => {
                          return  <SingleGroupCartProduct data={singleItem} key={id} groupno={groupId} memberId={memberId}/>
                        })
                      }
                      </ScrollView>
                    }
                    <View style={[styles.bottomSubTotal, {paddingVertical: 2}]}>
                      <Text style={{...FONTS.fineText, fontSize: 13, marginBottom: 5}}>Ksh. 0</Text>
                    </View>
                  </TouchableOpacity>
                })
              }
                          
              </ScrollView>
              <View style={styles.summaryPayments}>
                <View style={styles.topVATandSubTotal}>
                  <View style={styles.subtoal}><Text style={styles.subtexts}>Sub Total</Text> <Text style={styles.subtexts}>{calculateTotalAmount()}</Text></View>
                  <View style={styles.subtoal}><Text style={styles.subtexts}>VAT 16%</Text> <Text style={styles.subtexts}>{ratings}</Text></View>
                </View>
                <View style={styles.subtoal}>
                  <Text style={[styles.subtexts, {fontWeight: 500, paddingVertical: 5}]}>Total</Text>
                  <Text style={[styles.subtexts, {fontWeight: 500, paddingVertical: 5}]}>Ksh. {(parseFloat(calculateTotalAmount()) + ratings).toFixed(2)}</Text>
                </View>
              </View>
            </View>
            :
            <View style={styles.allCartProductsDisplay}>
              <Text style={{...FONTS.smallsubtext, color: "orange", fontSize: 13}}>Oops!! No groups available, atlist one of the member or you that can add groups to cart to be diaplayed here.</Text>
            </View>
          }

            {/* Bottom buttons */}
            <View style={styles.bottomLinksBtns}>
            {state.group.length !== 0 && <Button text="Bring to Table" style={[styles.button, {backgroundColor: "transparent"}]} onPress={() => navigation.goBack()}/>}
            <Button text={state.group.length === 0? "Add groups to cart to pay!!!":"Pay"} style={styles.button} onPress={state.group.length === 0?() => navigation.goBack() : () => navigateToPayBills(state.group)}/>
            </View>
          </View>
        </View>
      </View>

      {/* Join the group modal */}
      <Modal
            animationType="slide"
            visible={joingroup}
            transparent={true}
        >
            <View style={[styles.modalContainer, {alignSelf: stateLayout}]}>
                <View style={styles.modalContent}>
                    <TouchableOpacity style={styles.madalPayAmount}>
                        <View style={styles.barTopSectionView}></View>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.topCloseModal} onPress={closeTheModal}><X color="white" size={18}/></TouchableOpacity>
                    <View style={styles.groupTitle}><Text style={{...FONTS.subsmalltext, fontSize: 14, fontWeight: 700}}>Join {group?group:groupId} Group</Text></View>
                    {/* Modal content */}
                    <View style={styles.windowDisplay}>
                        <TextInput type="text" placeholder="Group name" value={group?group:groupId} style={styles.singleInput}/>
                        <TextInput type="text" placeholder="Enter your UserID" style={styles.singleInput} onChangeText={(id) => setMemberId(id)} value={memberId}/>
                        <TextInput type="phone" placeholder="Enter your phonenumber" style={styles.singleInput} onChangeText={(phone) => setMemberPhone(phone)} value={memberPhone}/>
                    </View>
                    <View style={styles.modalButtonOrder}>
                      <Button text="Create Group" style={styles.button} onPress={() => joinPersonGroup(groupId, memberId)}/>
                    </View>
                </View>
            </View>
        </Modal>

    </Layouts>
  );
}

const { width, height } = Dimensions.get("screen")
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.backgroundDark,
    position: "relative",
    maxWidth: 430,
  },
  navigationLinks: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    paddingHorizontal: 15,
    paddingVertical: 15,
    zIndex: 1,
  },
  offerViewPage: {
    width: "100%",
    height: "100%",
    margin: 0,
  },
  topImageView: {
    width: "100%",
    height: hp(20),
    position: "relative",
  },
  imagecover: {
    width: "100%",
    height: "100%",
  },
  barnextdoorbackground: {
    width: "100%",
    height: "100%",
  },
  imageZindexValues: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: 60,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingHorizontal: 10,
  },
  avatarImageDisplay: {
    width: 40,
    height: 40,
    borderRadius: 100,
    overflow: "hidden",
    backgroundColor: COLORS.gray,
  },
  imageset: {
    width: "100%",
    height: "100%",
  },
  textPreview: {
    marginLeft: 10,
  },
  title: {
    ...FONTS.fineText,
    fontSize: 14,
  },
  bottomContentView: {
    width: "100%",
    paddingHorizontal: 10,
    flex: 1,
  },
  topCartHeader: {
    width: "100%",
    paddingVertical: 5,
    marginVertical: 5,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  cartHeading: {
    ...FONTS.bigsubtext,
    fontSize: 13,
  },
  addButtonCart: {
    paddingHorizontal: 10, 
    paddingVertical: 5,
    backgroundColor: COLORS.backgroundLight,
    borderRadius: 10,
    ...FONTS.fineText,
  },
  allCartProductsDisplay: {
    width: "100%",
    backgroundColor: COLORS.backgroundLight,
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 15,
    maxHeight: "60%",
    position: "relative",
    overflow: "auto",
  },
  productsDisplay: {
    width: "100%",
    maxHeight: hp(20),
    overflow: "auto",
    scrollbars: "none",
  },
  summaryPayments: {
    paddingVertical: 1,
    backgroundColor: COLORS.backgroundLight,
    borderRadius: 10,
    marginVertical: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  topVATandSubTotal: {
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: COLORS.gray,
  },
  subtoal: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginVertical: 2,
  },
  subtexts: {
    ...FONTS.smallfineText,
    fontWeight: 300,
    fontSize: 14,
  },
  bottomLinksBtns: {
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
  },
  button: {
    borderWidth: 1,
    borderColor: COLORS.blue,
    marginVertical: 10,
    width: "90%",
  },

  // Group cart profile
  groupProfileName: {
    paddingVertical: 5,
    paddingHorizontal: 2,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  groupName: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  personalView: {
    width: "100%",
    paddingVertical: 5,
  },
  topuserNmae: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  bottomSubTotal: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  // Join the group
     // Modal styles
     modalContainer: {
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      paddingTop: 5,
      width: wp(100),
      height: hp(100),
      maxWidth: 430,
    },
    modalContent: {
      backgroundColor: COLORS.backgroundDark,
      padding: 20,
      width: '100%', 
      height: hp(50),
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      elevation: 5,
    },
    modalContainerGroupPayment: {
      backgroundColor: COLORS.backgroundDark,
      padding: 20,
      width: '100%', 
      height: "35%",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      elevation: 5,
    },
    modalContainerGroupPaymentLink: {
      backgroundColor: COLORS.backgroundDark,
      padding: 20,
      width: '100%', 
      height: "45%",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      elevation: 5,
    },
    madalPayAmount: {
      width: "100%",
      height: 5,
      justifyContent: "center",
      alignItems: "center",
    },
    modelTextColor: {
      paddingVertical: 5,
      width: "100%",
      textAlign: "center",
      ...FONTS.bigsubtext,
    },
    barTopSectionView: {
      borderTopWidth: 3,
      height: 1,
      borderTopColor: "white",
      width: 52,
      borderRadius: 100,
      marginBottom: 5,
      marginTop: -20,
    },
    topCloseModal: {
      width: "100%",
      paddingHorizontal: 5,
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: 'row',
    },
    windowDisplay: {
      width: "100%",
      flex: 1,
      paddingVertical: 5,
    },
    groupTitle: {
      justifyContent: "center",
      alignItems: "center",
      paddingVertical: 5,
    },
     modalButtonOrder: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginBottom: 40,
      maxWidth: 430,
    },
    modalButtonOrderGroupChart: {
      marginVertical: 10,
    },
    singleInput: {
      padding: 10,
      borderRadius: 10,
      backgroundColor: COLORS.backgroundLight,
      marginVertical: 5,
      color: COLORS.gray,
      border: "none",
    },
    button: {
      width: "90%",
    },
    buttonGroup: {
      width: "96%",
      marginVertical: 5,
      fontSize: 14,
    },
});
export default CartScreen;
