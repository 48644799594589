import { COLORS, IMAGES, FONTS } from "../../../constants/index";
import {
  StyleSheet,
  View,
  Text,
  Image,
  Dimensions,
  ActivityIndicator,
} from "react-native";
import React, { useState, useEffect } from "react";
import Button from "../../../components/Button";
import Logo from "../../../assets/images/routelogo.svg";
import Layouts from "../../Layouts/ScreenView";
import { useCart } from "../../../context/SinglecartContext";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import axios from "axios";
import Svg, { SvgXml, Path } from "react-native-svg";
import { useMerchantStore } from "../../../store/merchant_store";

function HomeScreen({ navigation, route }) {
  const { deviceFrame } = useCart();
  const [merchantData, setMerchantData] = useState();
  const { merchant, addMerchant } = useMerchantStore();
  // const { merchantSlug } =  route.params || {};
  const [order, setOrder] = useState(false);
  const [orderData, setOrderData] = useState({});
  const logo2 = `
 <svg width="295" height="108" viewBox="0 0 295 108" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path d="M30.7913 60.3194C38.0128 58.0905 41.9857 52.841 41.9857 45.5275V45.3827C42.0354 43.4325 41.7046 41.4914 41.0121 39.6705C40.3196 37.8497 39.279 36.1847 37.9499 34.7707C34.7012 31.5591 29.8681 29.8613 23.9732 29.8613H1.48267C1.08956 29.8617 0.712653 30.0198 0.434679 30.3009C0.156706 30.582 0.000379598 30.9632 6.93026e-07 31.3608V77.2419C-0.000379365 77.6401 0.155573 78.0221 0.433587 78.3041C0.711602 78.5861 1.08893 78.7449 1.48267 78.7457H7.40907C7.80207 78.7449 8.17875 78.5867 8.45665 78.3056C8.73454 78.0246 8.89099 77.6436 8.89175 77.2462V61.8768H21.4559L32.709 78.0205C32.8649 78.244 33.0716 78.4263 33.3117 78.5522C33.5518 78.6782 33.8182 78.744 34.0886 78.7442H40.5159C40.8257 78.7441 41.1294 78.6577 41.3938 78.4945C41.6582 78.3312 41.873 78.0975 42.0147 77.8189C42.1564 77.5403 42.2194 77.2277 42.1968 76.9152C42.1743 76.6028 42.0671 76.3026 41.887 76.0478L30.7913 60.3194ZM32.9609 45.8734V46.0109C32.9609 50.7756 29.1898 53.8541 23.3593 53.8541H5.80619C5.78775 53.855 5.76956 53.8494 5.75469 53.8384C5.73983 53.8273 5.72921 53.8114 5.72461 53.7933C5.71825 53.7788 5.71648 53.7627 5.71956 53.7471C5.72263 53.7316 5.7304 53.7174 5.74179 53.7065L8.69139 51.9942C8.75209 51.959 8.80256 51.9082 8.83772 51.8469C8.87289 51.7857 8.89152 51.7162 8.89175 51.6454V38.0968H23.2906C29.436 38.0968 32.9609 40.9307 32.9609 45.8734Z" fill="white"/>
 <path d="M76.3986 29.0357C73.0216 28.9877 69.6692 29.6243 66.5394 30.908C63.4096 32.1917 60.5658 34.0965 58.1758 36.5099C53.5771 41.2577 51.0236 47.6533 51.073 54.2993V54.4368C51.073 68.7655 61.9025 79.5672 76.2612 79.5672C79.6391 79.6148 82.9921 78.9776 86.1224 77.6931C89.2527 76.4087 92.0969 74.503 94.487 72.0887C99.0847 67.3404 101.637 60.9448 101.587 54.2993V54.1546C101.588 39.8388 90.7603 29.0357 76.3986 29.0357ZM76.3986 71.2029C67.2636 71.2029 60.3697 63.9372 60.3697 54.3036V54.1589C60.3697 44.5644 67.2006 37.3291 76.2555 37.3291C85.3934 37.3291 92.2844 44.6237 92.2844 54.2978V54.4353C92.2901 63.9966 85.4593 71.2029 76.3986 71.2029Z" fill="white"/>
 <path d="M151.888 29.8652H145.886C145.503 29.8652 145.136 30.0189 144.865 30.2924C144.595 30.566 144.442 30.9371 144.442 31.3242V57.967C144.442 66.4586 140.256 71.1379 132.652 71.1379C125.117 71.1379 120.796 66.2169 120.796 57.6269V31.3285C120.796 30.9417 120.644 30.5708 120.373 30.2973C120.103 30.0238 119.736 29.8699 119.354 29.8696H113.343C112.961 29.8699 112.594 30.0238 112.323 30.2973C112.053 30.5708 111.901 30.9417 111.9 31.3285V57.9714C111.9 71.6576 119.413 79.5065 132.509 79.5065C145.737 79.5065 153.324 71.507 153.324 57.5574V31.3242C153.323 30.9386 153.172 30.5688 152.903 30.2955C152.634 30.0222 152.269 29.8675 151.888 29.8652Z" fill="white"/>
 <path d="M202.363 29.8652H162.845C162.395 29.8656 161.964 30.046 161.646 30.3669C161.328 30.6878 161.149 31.123 161.147 31.5774L161.053 36.3783C161.053 36.8335 161.232 37.2698 161.55 37.5917C161.869 37.9135 162.3 38.0945 162.75 38.0949H178.126V77.0287C178.127 77.4836 178.306 77.9196 178.624 78.2411C178.943 78.5626 179.374 78.7434 179.824 78.7438H185.388C185.838 78.743 186.269 78.5619 186.587 78.2402C186.906 77.9184 187.085 77.4823 187.085 77.0272L186.995 40.3006L183.383 38.1615L183.393 38.1007H202.276C202.725 38.1011 203.156 37.9211 203.474 37.6004C203.792 37.2796 203.972 36.8443 203.973 36.3899L204.067 31.5861C204.068 31.3598 204.024 31.1356 203.938 30.9265C203.853 30.7173 203.727 30.5273 203.569 30.3674C203.41 30.2075 203.222 30.0808 203.015 29.9946C202.809 29.9084 202.587 29.8645 202.363 29.8652Z" fill="white"/>
 <path d="M249.528 70.652H220.919V58.144H246.107C246.427 58.1436 246.733 58.0152 246.959 57.7867C247.185 57.5582 247.312 57.2485 247.312 56.9254V51.2691C247.312 50.946 247.185 50.6363 246.959 50.4078C246.733 50.1793 246.427 50.0508 246.107 50.0505H220.919V40.2084L218.01 38.0374L218.018 37.9549H218.005H249.187C249.507 37.9545 249.813 37.826 250.039 37.5976C250.265 37.3691 250.392 37.0593 250.392 36.7363V31.08C250.392 30.7569 250.265 30.4471 250.039 30.2187C249.813 29.9902 249.507 29.8617 249.187 29.8613H213.232C212.913 29.8617 212.607 29.9902 212.381 30.2187C212.155 30.4471 212.028 30.7569 212.027 31.08V77.5212C212.028 77.8443 212.155 78.154 212.381 78.3825C212.607 78.611 212.913 78.7395 213.232 78.7399H249.529C249.849 78.7395 250.155 78.611 250.381 78.3825C250.607 78.154 250.734 77.8443 250.734 77.5212V71.8649C250.732 71.5426 250.604 71.2341 250.378 71.0069C250.152 70.7796 249.847 70.652 249.528 70.652Z" fill="white"/>
 <path d="M241.609 107.996C233.395 108.019 225.289 106.106 217.935 102.409C217.695 102.289 217.482 102.123 217.306 101.919C217.13 101.716 216.996 101.479 216.91 101.223C216.825 100.967 216.79 100.697 216.808 100.427C216.826 100.158 216.896 99.8944 217.015 99.6524C217.134 99.4103 217.298 99.1943 217.499 99.0166C217.7 98.8388 217.934 98.7029 218.187 98.6166C218.44 98.5302 218.708 98.4951 218.974 98.5133C219.241 98.5315 219.501 98.6025 219.741 98.7225C226.534 102.138 234.022 103.904 241.609 103.881C249.546 103.88 257.367 101.942 264.405 98.2313C271.444 94.5205 277.493 89.1465 282.039 82.5657C286.585 75.985 289.494 68.3919 290.517 60.4314C291.541 52.4708 290.65 44.378 287.92 36.8402C285.19 29.3025 280.701 22.5424 274.835 17.1342C268.969 11.726 261.899 7.8294 254.225 5.77538C246.552 3.72135 238.502 3.57058 230.758 5.33585C223.015 7.10111 215.807 10.7302 209.747 15.9151C209.335 16.2676 208.802 16.4404 208.264 16.3954C207.727 16.3503 207.229 16.0911 206.88 15.6748C206.532 15.2585 206.361 14.7192 206.405 14.1755C206.45 13.6319 206.706 13.1284 207.118 12.7758C213.678 7.16273 221.48 3.23366 229.862 1.32232C238.244 -0.589019 246.959 -0.426186 255.266 1.79701C263.573 4.02021 271.226 8.2381 277.576 14.0924C283.926 19.9467 288.786 27.2645 291.741 35.4242C294.697 43.5839 295.662 52.3445 294.554 60.9619C293.445 69.5793 290.297 77.799 285.376 84.9227C280.455 92.0464 273.906 97.8637 266.287 101.881C258.667 105.897 250.201 107.995 241.609 107.996Z" fill="url(#paint0_linear_2512_43)"/>
 <defs>
 <linearGradient id="paint0_linear_2512_43" x1="251.496" y1="0.431984" x2="246.496" y2="147.143" gradientUnits="userSpaceOnUse">
 <stop stop-color="#DA06FF"/>
 <stop offset="1" stop-color="#1677EC"/>
 </linearGradient>
 </defs>
 </svg> 
 `;

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const slugParam = searchParams.get("merch");
    const orderParam = searchParams.get("order");

    if (orderParam) {
      setOrder(true);
      let new_data = JSON.parse(JSON.stringify([orderParam]));
      // setOrderData(new_data);
      // alert(new_data.total_amount);
    } else {
      setOrder(false);
      // setOrderData({});
    }
    fetchUserBusinesses(slugParam);
  }, []);

  const fetchUserBusinesses = async (slug) => {
    try {
      const response = await axios.get(
       // `https://backoffice.route.money/api/v1/business/merchant/detail/${slug}`
       `https://routemoney-storefront.deveint.live/api/v1/business/merchant/detail/${slug}`
      );
      setMerchantData(response.data);
      console.log(`Merchant Data inZustand: ${merchant}`);
      addMerchant(response.data);

      localStorage.setItem("merchantData", JSON.stringify(response.data));
      console.log("merch data: ", response.data);
    } catch (error) {
      console.error("Failed to fetch user single business data:", error);
    }
  };
  console.log(`Merchant Data in Zustand---: ${JSON.stringify(merchant)}`);
  return (
    <Layouts>
      <View style={styles.container}>
        {merchantData ? (
          <View
            style={{ flex: 5, justifyContent: "center", alignItems: "center" }}
          >
            <View style={styles.qrcodeimage}>
              <Image
                source={`https://backoffice.route.money/api/v1${merchantData.profile_image}`}
                style={styles.qrimage}
              />
            </View>
            {orderData.length > 0 ? (
              <View style={styles.buttoncontainer}>
                <Button
                  text={"Pay Ksh. 3.56"}
                  style={styles.button}
                  onPress={() =>
                    navigation.navigate("OfferScreen", { merchantData })
                  }
                />
              </View>
            ) : (
              <View style={styles.buttoncontainer}>
                <Button
                  text="View Menu"
                  style={styles.button}
                  onPress={() =>
                    navigation.navigate("OfferScreen", { merchantData })
                  }
                />
              </View>
            )}
          </View>
        ) : (
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "64px",
              marginVertical: "auto",
            }}
          >
            <ActivityIndicator />
          </View>
        )}

        <View style={styles.footerstyles}>

        <View style={styles.footertext}>
            <Text style={styles.text}>
              {/* Get paid for dining at your favorite eatery */}
              Powered by
            </Text>
            <Text
              style={[
                styles.text,
                { color: "skyblue", textDecorationLine: "underline" },
              ]}
            >
              {/* Download the route app */}
            </Text>
          </View>


          <View style={styles.footerimagecontainer}>
            {/* <Image source={IMAGES.logo} style={styles.qrimage} resizeMode="contain"/> */}
    
      
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(logo2)}`}
              className="App-logo"
              alt="logo"
              style={styles.routeLogo}
            />
          </View>
      
        </View>
      </View>
    </Layouts>
  );
}

const { width } = Dimensions.get("screen");
const styles = StyleSheet.create({
  container: {
    width: wp(100),
    height: hp(100),
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.backgroundDark,
    maxWidth: 430,
  },
  qrcodeimage: {
    width: 200,
    height: 200,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    backgroundColor: "black",
    borderRadius: 5,
    marginVertical: 20,
  },
  qrimage: {
    width: "100%",
    height: "100%",
    borderRadius: 5,
  },
  routeLogo: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: 200,
  },
  buttoncontainer: {
    padding: 10,
    marginTop: 60,
  },
  button: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: COLORS.blue,

    marginVertical: 10,
    width: 290,
  },
  footerstyles: {
    flex: 1,
   // justifyContent: "flex-end",
    alignItems: "center",
    marginVertical: 40,
    flexDirection: "row"
  },
  footerimagecontainer: {
    width: 150,
    height: 60,
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 5,
  },
  footertext: {
    justifyContent: "center",
    alignItems: "center",
    marginRight: 7,
  },
  text: {
    // ...FONTS.fineText,
    color: "white",
    fontSize: 15,
    marginVertical: 5,
  },
});
export default HomeScreen;
