import { COLORS, IMAGES, FONTS} from "../../../../constants/index";
import { StyleSheet, View, Text, Dimensions, TouchableOpacity, Image, Alert, Modal, ScrollView } from "react-native";
import React, { useState } from "react";
import { X, Check} from 'lucide-react';
import Button from "../../../../components/Button";
import { useCart } from "../../../../context/SinglecartContext";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import { useGroupCart } from "../../../../context/GroupmanagerContext";

function Singleoffer({ navigation, data, groupno, memberId}) {

  const { dispatch, getQuantityOfItem, stateLayout } = useCart();
  const {addToCart, removeFromCart, state, getitemQuantityPerEach} = useGroupCart();

  const [offermodal, setOffermodal] = useState(false)

  // Modal offers
  const openModalFilter = () => {
    setOffermodal(true)
  }

  const closeTheModal = () => {
    setOffermodal(false)
  }

  const bringToTable = async (product) => { 
    const finalProduct = { ...product, orderType: "table" }; 
    if(groupno && memberId ) {
      addToCart(groupno, memberId, finalProduct)
    }else{
      dispatch({ type: 'ADD_TO_CART', payload: finalProduct });
    }
  };
  const addItemToCart = (product) => {
    if(groupno && memberId ) {
      addToCart(groupno, memberId, product);
    }else{
      dispatch({ type: 'ADD_TO_CART', payload: product })
    }
  }
  const generalRemoveFromCart = (product) => {
    if(groupno && memberId ) {
      removeFromCart(groupno, memberId, product)
    }else{
      dispatch({ type: 'REMOVE_FROM_CART', payload: product });
    }
  }

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  // const truncatedProducts = products.map(product => ({
  //   ...product,
  //   truncatedName: truncateText(product.name, 30),
  //   truncatedesc: truncateText(product.description, 40),
   
   
  // }));

  return (
    <>
     <TouchableOpacity style={styles.singleOfferView} onPress={openModalFilter}>
        <View style={{flexDirection: "row", alignItems: "center"}}>
            <View style={[styles.singleOfferImg, {backgroundColor: "white"}]}>
              <Image source={data.image} style={styles.singleOfferTagImg}/>
            </View>
            <View style={styles.offertextsDesc}>
              <Text style={[styles.amountOffer, {fontSize: 16}]}>{data.name}</Text>
              <Text style={[ {color: COLORS.grayText, fontSize: 12}]}>{truncateText(data.description, 40)}</Text>
              <Text style={[styles.amountOffer, {fontWeight: 700, fontSize: 14}]}>Ksh {data.price}</Text>
            </View>
        </View>
        <View style={styles.handleChnageCart}>
            <TouchableOpacity style={styles.cartBtnsOutline} onPress={() => generalRemoveFromCart(data)}><Text style={styles.actions}>-</Text></TouchableOpacity>
            <Text style={styles.cartDisplayCapacity}>{groupno ? getitemQuantityPerEach(state, groupno, memberId, data.id):getQuantityOfItem(data.id)}</Text>
            <TouchableOpacity style={styles.cartBtnsOutline} onPress={() => addItemToCart(data)}><Text style={styles.actions}>+</Text></TouchableOpacity>
        </View>
     </TouchableOpacity>
     
    {/* Modal */}
    <Modal
        animationType="slide"
        visible={offermodal}
        transparent={true}
    >
        <View style={[styles.modalContainer, {alignSelf: stateLayout}]}>
            <View style={styles.modalContent}>
              
                <TouchableOpacity style={styles.madalPayAmount}>
                  <View style={styles.barTopSectionView}></View>
                </TouchableOpacity>
                <View style={styles.navigationLinks}>
                  <TouchableOpacity onPress={closeTheModal}>
                    <X color="white" size={20} style = {{ backgroundColor: "gray", borderRadius: 50, padding: 3 }} />
                  </TouchableOpacity>
                </View>

                {/* Modal content */}
                <View style={styles.windowDisplay}>
                    <View style={styles.imageModalContainer}>
                         <Image source={data.image} style={styles.singleOfferTagImgModal} resizeMode="stretch"/>
                    </View>
                    <ScrollView 
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}
                    >
                      <View style = {{ marginHorizontal: hp(2), justifyContent: 'center', alignItems: 'center' }}>
                        <View style={styles.textContainer}>
                          <Text style = {[ FONTS.smallfineText, { color: 'white', paddingVertical: 5, fontWeight: 700, fontSize: 16 }]}>{data.name}</Text>
                          <Text style = {[ FONTS.verySmallfineText, {color: COLORS.subtitlecolor, fontWeight: 500, fontSize: 16}]}>
                            {data.description}
                            {/* {truncateText(data.description, 5)} */}
                          </Text>
                          {/* <Text style={[FONTS.subsmalltext, {paddingVertical: 5}]}>KSH {data.price}</Text> */}
                          <Text style={[styles.amountOffer, {fontWeight: 700, fontSize: 14}]}>Ksh {data.price}</Text>
                        </View>
                        <View style={styles.modalActionButton}>
                            <View style={styles.handleChnageCart}>
                                <TouchableOpacity style={styles.cartBtnsOutline} onPress={() => generalRemoveFromCart(data)}>
                                    <Text style={styles.actions}>-</Text>
                                </TouchableOpacity>
                                <Text style={styles.cartDisplayCapacity}>{getQuantityOfItem(data.id)}</Text>
                                <TouchableOpacity style={styles.cartBtnsOutline} onPress={() => addItemToCart(data)}>
                                    <Text style={styles.actions}>+</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={styles.modalButtonOrder}>
                            <Button text="Bring to Table" style={styles.button} onPress={() => bringToTable(data)}/>
                        </View>
                      </View>
                    </ScrollView>
                </View>
            </View>
        </View>
    </Modal>
    </>
  );
}

const styles = StyleSheet.create({
  navigationLinks: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    paddingHorizontal: 15,
    paddingVertical: 15,
    zIndex: 1,
  },
  singleOfferView: {
    marginTop: 2,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: "#343643",
    borderRadius: 10,
    height: 83,
    width: "100%",
    paddingl: hp(0.5),
    marginBottom: 10,
  },
  singleOfferImg: {
    height: hp(9),
    width: hp(9),
    objectFit: 'contain',
    borderRadius: 10,
    overflow: "hidden",
  },
  singleOfferTagImg: {
    width: "100%",
    height: "100%",
    borderRadius: 10,
  },
  offertextsDesc: {
    marginLeft: 15,
  },
  amountOffer: {
    ...FONTS.fineText,
    fontSize: 14,
    paddingVertical: 3,
  },
  handleChnageCart: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginRight: 5,
  },
  cartBtnsOutline: {
    width: 20,
    height: 20,
    textAlign: "center",
    borderRadius: 100,
    borderWidth: 1,
    borderColor: COLORS.white,
    justifyContent: "center",
    alignItems: "center",
  },
  actions: {
    ...FONTS.bigsubtext,
    fontSize: 11,
  },
  cartDisplayCapacity: {
    marginHorizontal: 10,
    ...FONTS.fineText,
  },
  foodmenuText: {
    ...FONTS.subsmalltext,
    paddingVertical: 5,
    fontSize: 11,
  },
  
  // Modal styles
  modalContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: "relative",
    width: wp(100),
    height: hp(100),
    maxWidth: 430,
  },
  modalContent: {
    backgroundColor: COLORS.backgroundDark,
    width: '100%', 
    height: hp(90),
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    elevation: 5,
  },
  modalContentFoods: {
    backgroundColor: COLORS.backgroundDark,
    padding: 20,
    width: '100%', 
   height: hp(82),
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    elevation: 5,
  },
  madalPayAmount: {
    width: "100%",
    height: 5,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 15,
    zIndex: 1
  },
  modelTextColor: {
    paddingVertical: 5,
    width: "100%",
    textAlign: "center",
    ...FONTS.bigsubtext,
  },
  barTopSectionView: {
    borderTopWidth: 3,
    height: 1,
    borderTopColor: "white",
    width: 52,
    borderRadius: 100,
    marginBottom: 5,
    marginTop: -20,
  },
  topCloseModal: {
    width: "100%",
    paddingHorizontal: 5,
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'row',
  },
  button: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: COLORS.blue,
    marginVertical: 10,
    width: "90%",
  },
  windowDisplay: {
    width: "100%",
    flex: 1,
    margin: 0,
  },
  imageModalContainer: {
    width: "100%",
    height: 310,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    overflow: "hidden",
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
  },
  singleOfferTagImgModal: {
    width: "100%",
    height: "100%",
  },
  textContainer: {
    width: "100%",
    paddingVertical: 5,
    paddingHorizontal: 10,
    backgroundColor: COLORS.backgroundLight,
    marginVertical: hp(4),
    borderRadius: 10,
  },
  textMain: {
    ...FONTS.smallfineText,
    fontSize: 14,
  },
  modalActionButton: {
    marginVertical: 5,
  },
  modalButtonOrder: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },

  // Food styles
  singleOfferTagImgModal: {
    width: "100%",
    height: "100%",
  },
  textMain: {
    ...FONTS.smallfineText,
    fontSize: 11,
  },
  modalActionButton: {
    marginVertical: 5,
  },
  modalButtonOrder: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  listcheckedView: {
    width: "100%",
  },
  singleListMarked: {
    paddingVertical: 2,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  listitle: {
    ...FONTS.smallfineText,
    fontSize: 11,
  },
  listcheckbox: {
    width: 10,
    height: 10,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: COLORS.white,
  },

  // Offers
  singleOfferView: {
    marginTop: 2,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: "#343643",
    borderRadius: 10,
    height: 83,
    width: "100%",
    padding: hp(0.5),
    marginBottom: 10,
  },
  singleOfferImg: {
    height: hp(9),
    width: hp(9),
    objectFit: 'contain',
    borderRadius: 10,
    overflow: "hidden",
  },
  singleOfferTagImg: {
    width: "100%",
    height: "100%",
    borderRadius: 10,
  },
  offertextsDesc: {
    marginLeft: 15,
  },
  amountOffer: {
    ...FONTS.fineText,
    fontSize: 14,
    paddingVertical: 3,
  },
  handleChnageCartOffers: {
    justifyContent: "center",
    alignItems: "center",
    marginRight: 5,
  },
  cartBtnsOutline: {
    width: 20,
    height: 20,
    textAlign: "center",
    borderRadius: 100,
    borderWidth: 1,
    borderColor: COLORS.white,
    justifyContent: "center",
    alignItems: "center",
  },
  actions: {
    ...FONTS.bigsubtext,
    fontSize: 11,
  },
  cartDisplayCapacity: {
    marginHorizontal: 10,
    ...FONTS.fineText,
  },
});
export default Singleoffer;
