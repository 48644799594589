import { COLORS, IMAGES, FONTS } from "../../../constants/index";
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  Dimensions,
  TouchableOpacity,
  Image,
  FlatList,
  Modal,
  Picker,
  ActivityIndicator,
  ScrollView,
} from "react-native";
import React, { useState, useEffect } from "react";
import { ArrowLeft, Plus } from "lucide-react";
import Button from "../../../components/Button";
import SingleItemReport from "./components/SingleItemReport";
import { X } from "lucide-react";
import { TextInput } from "react-native-web";
import { useCart } from "../../../context/SinglecartContext";
import Layouts from "../../Layouts/ScreenView";
import { useSplitContext } from "../../../context/SplitBillsContext";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import Forms from "./utilities/Forms";
import axios from "axios";
import { HandleTransactions } from "../../../context/TransactionContext";
import "../../../assets/script";
import qs from "qs";
import { useGroupCart } from "../../../context/GroupmanagerContext";
import { WebView } from "react-native-webview";

// import { InAppBrowser } from "react-native-inappbrowser-reborn";

function RecieptScreen({ navigation, route, layoutState }) {
  const { state, calculateTotalAmount, getQuantityOfItem, stateLayout } =
    useCart();
  const {
    createGroup,
    joinGroup,
    leaveGroup,
    addToCart,
    removeFromCart,
    clearCart,
  } = useGroupCart();
  const {
    openSplitBillModal,
    openCustomizePeopleToSplitModal,
    openMediaModalToShareLinks,
    splitBillModal,
    sharePlitLink,
    customizePeopleAmPayingFor,
    ontableCount,
    peopleAmPayingFor,
    generateUrl,
    requestedAmount,
    myPayments,
  } = useSplitContext();
  const { billStatus, showPersonaStatus, product } = route.params;
  const { allMerchantInfo } = route.params || {};
  const merchantData = JSON.parse(localStorage.getItem("merchantData"));

  console.log("state: ", state);
   console.log("merchant at receipt",merchantData);
   console.log("all merchant info", allMerchantInfo);

  // Split bills >> Split equally
  const ratings = parseFloat(
    (
      calculateTotalAmount() -
      (calculateTotalAmount() - calculateTotalAmount() * 0.116)
    ).toFixed(2)
  );
  const amountToSplit = (parseFloat(calculateTotalAmount()) + ratings).toFixed(
    2
  );

  // Informations
  const [fullName, setFullName] = useState("");
  const [userEmail, setUseEmail] = useState("");
  const [isCustomerFormOpen, setIsCustomerFormOpen] = useState(false); // customer modal

  const [userPhoneNumber, setUserPhoneNo] = useState(null);
  const [seatingPosition, setSeatingPosition] = useState(null);
  const [tableTime, setTableTime] = useState(null);
  const [numberOfGuests, setNumberOfGuests] = useState(null);
  const [pickupTime, setPickUpTime] = useState(null);
  const [userAddress, setUserAddress] = useState(null);
  const [isWebVisible, setIsWebVisible] = useState(false);

  const [firstname, setFirstName]=useState("");
  const [lastname, setLastname]=useState("");

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("customerData"))) {
      setIsCustomerFormOpen(true);
    } else {
      var userData = JSON.parse(localStorage.getItem("customerData"));
      setFullName(userData.consumer_name);
      setUseEmail(userData.consumer_email);
      setUserPhoneNo(userData.consumer_phone);
    }
  }, []);

  const closeCustomerDetailsModal = () => {
    setIsCustomerFormOpen(false);
  };

  const onWebModalClose = () => {
    setIsWebVisible(false);
  };

  const saveCustomerData = () => {
    localStorage.setItem(
      "customerData",
      JSON.stringify({
        consumer_name: fullName,
        consumer_email: userEmail,
        consumer_phone: userPhoneNumber,
      })
    );
    if (fullName && userEmail && userPhoneNumber) {
      setIsCustomerFormOpen(false);
    }
  };

  const orderDetailsShareOnWhatsapp = {
    total_bill: amountToSplit,
    customer_name: fullName,
    customer_email: userEmail,
    customer_phone: userPhoneNumber,
    seating_position: seatingPosition,
    number_guests: numberOfGuests,
    pickup_time: pickupTime,
    customer_location: userAddress,
    customer_table_time: tableTime,
  };

  const [responseMessage, setResponseMessage] = React.useState(null);
  responseMessage ? alert(responseMessage) : null;

  const [paymentModalDisplay, setpaymentModalDisplay] = useState(false);
  const openPayModal = () => setpaymentModalDisplay(true);
  const closePayModal = () => setpaymentModalDisplay(false);


  // updated quickteller payment

  const checkoutData = {
    merchantCode: "ROUTEK0001",
    //amount: totalAmountAndVat,
    orderId:"RDFRT5434",
    customerId: "JKL",
  //   customerFirstName: firstname,
  //   customerSecondName: lastname,
  //   customerEmail: userEmail,
  //   customerMobile: userPhoneNumber,
  //  callbackUrl: callbackUrl
  }
  // updated quicktellrt payment

  const CheckoutData = {
    transactionReference: `ROUTE_TZD_${(Math.random() + 1)
      .toString(36)
      .substring(9)}`.toUpperCase(),
    orderId: "ORD#20240404011802EF0A", //get this value from the profile
    amount: Number((parseFloat(calculateTotalAmount()) + ratings).toFixed(2)),
    dateOfPayment: "2024-04-05T08:24:02.064Z",
    redirectUrl:
      "http://localhost:3000/services/parking/interswitch-payment-summary?paymentType=pass&serviceType=parking",
    narration: "Fries Payment",
    expiryTime: "2024-04-05T10:24:02.064Z",

    customerId: userEmail,
    customerFirstName: fullName,
    customerSecondName: fullName,
    customerEmail: userEmail,
    customerMobile: userPhoneNumber,
    merchantCode: "ROUTEK0001",
    terminalType: "WEB",
    domain: "ISWKE",
    currencyCode: "KES",
    displayPrivacyPolicy: "false",
    fee: "0",
    iconUrl: "",
    providerIconUrl: "",
    primaryAccentColor: "#9e5be1",
    redirectMerchantName: "Return to Customer Portal",
    merchantName: "JS Checkout Merch",
    customerCity: "Nairobi",
    customerCountry: "Kenya",
    customerState: "Nairobi",
  };

  // updated make paymnet
  const makePayment = async() => {
   try {
      const customHeaders = {
        "Content-Type": "application/json",
      };

      const response = await axios.post(
        // replace this api with the api to the getway on the backend
        "https://routemoney-storefront.deveint.live/api/v1/business/quickteller-checkout/",
        checkoutData,
        {
          headers: customHeaders,
        }
      );

      if (response.status >= 200 && response.status < 300) {
        console.log("response redirect url: ",response.data.responseUrl);
        // on success, consume and redirect the quickteller url for payment
        const redirectUrl = response.data.responseUrl;
        
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          console.error(
            "Payment Failed. Server responded with:",
            response.status,
            response.statusText
          );
        }
      } else {
        console.error(
          "Error adding to cart. Server responded with:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  }
  // end updated make payment

  const MakePayment = () => {
    //
    axios
      .post(
        "https://gatewaybackend-uat.quickteller.co.ke/ipg-backend/api/checkout",
        JSON.stringify(checkoutData),
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        let url = response.request.responseURL;
        console.log("url: ", response.request);
        console.log("url: ", url);
        // return InAppBrowser.open(url);
      })
      .catch((error) => console.error("IPG error: ", error));
    return;
  };

  const MpesaPaymentoptions = {
    transactionReference: `ROUTE_TZD_${(Math.random() + 1)
      .toString(36)
      .substring(9)}`.toUpperCase(),
    merchantCode: "ROUTEK0001",
    currencyCode: "KES",
    amount: Number((parseFloat(calculateTotalAmount()) + ratings).toFixed(2)),
    orderId: "ORD#20240404011802EF0A", //get this value from the profile
    terminalType: "WEB",
    paymentItem: "MMO",
    terminalId: "3TLP0001",
    isLog: 0,
    channel: "web",
    redirectUrl: "#",
    domain: "ISWKE",
    provider: "702",
    customerName: fullName,
    customerEmail: userEmail,
    customerMobile: userPhoneNumber,
  };

  function paymentCallback(response) {
    console.log(response);
  }

  var samplePaymentRequest = {
    merchant_code: "ROUTEK0001",
    pay_item_id: `ROUTE_TZD_${(Math.random() + 1)
      .toString(36)
      .substring(9)}`.toUpperCase(),
    txn_ref: "sample_txn_ref_123",
    site_redirect_url:
      "https://qa.interswitchng.com/webpay/Demo/ResponsePage/?",
    amount: Number((parseFloat(calculateTotalAmount()) + ratings).toFixed(2)),
    currency: "KES", // ISO 4217 numeric code of the currency used
    onComplete: paymentCallback,
    mode: "TEST",
  };

  const payViaMpesa = async () => {
  
    axios
      .post(
        "https://gatewaybackend-uat.quickteller.co.ke/ipg-backend/api/checkout",
        JSON.stringify(checkoutData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        let url = response.request.responseURL;
        console.log("url: ", url);
        // return InAppBrowser.open(url);
      })
      .catch((error) => console.error("IPG error: ", error));
    return;
  };

  const generalRemoveFromCart = (product) => {
    if (groupno && memberId) {
      removeFromCart(groupno, memberId, product);
    } else {
      dispatch({ type: "REMOVE_FROM_CART", payload: product });
    }
  };

 
  const paymentOnDelivery = async (product, amount) => {
     const totalAmountAndVat = amount;

    const newProductIdQuantityMapping = [
      ...new Set(product.map((item) => item.id)),
    ].map((item) => {
      const newProduct = { product: item, quantity: getQuantityOfItem(item) };
      return newProduct;
    });

    const productDetails = {
  products: newProductIdQuantityMapping,
  total_amount: totalAmountAndVat,
  order_type: paymentOptionsPlan,
  merchant_id: merchantData.business,
  customer_email: userEmail,
  phone_number: userPhoneNumber,
  address: userAddress,
  customer_name: fullName,
  user_id : 0
    }

     // Log the URL and request payload for debugging
  console.log("pay on deliverly URL: ", "https://routemoney-storefront.deveint.live/api/v1/business/order/create");
  console.log("Request Payload: ", productDetails);
    // navigation.navigate("SuccessScreen", { merchantData, totalAmountPaid: totalAmountAndVat, brandname: allMerchantInfo.name, billStatus: billStatus, paymentOptionsPlan: "Delivery"});
    // localStorage.setItem("cartItems", []);
    // closePayModal();

    try {
      const customHeaders = {
        "Content-Type": "application/json",
      };

      const response = await axios.post(
        "https://routemoney-storefront.deveint.live/api/v1/business/order/create",
        productDetails,
        {
          headers: customHeaders,
        }
      );

      if (response.status >= 200 && response.status < 300) {
        navigation.navigate("SuccessScreen", {
          merchantData,
          totalAmountPaid: totalAmountAndVat,
          brandname: allMerchantInfo.name,
          billStatus: billStatus,
          paymentOptionsPlan: "Delivery",
        });
        closePayModal();
        localStorage.setItem("cartItems", []);
        setResponseMessage(response.data.message);
      } else {
        console.error(
          "Error adding to cart. Server responded with:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

 
  // updated card and mpesa payment

  const paymentMpesaCard = async(product,paymentOptionsPlan, amount)=>{
    const partialAmountAndVat = amount * 100;
    const totalAmountAndVat = Math.ceil(partialAmountAndVat);

    const newProductIdQuantityMapping = [
      ...new Set(product.map((item) => item.id)),
    ].map((item) => {
      const newProduct = { product: item, quantity: getQuantityOfItem(item) };
      return newProduct;
    });

 
      // Get current URL and parameters
  const currentUrl = window.location.href;
  const urlParams = new URLSearchParams(window.location.search);
  const merchname = urlParams.get('merch'); // Get the merch parameter


  const redirectUrl = `${currentUrl}/SuccessScreen`; // Construct the callback URL
     const namestring=fullName.trim().split(" ");

     setFirstName(namestring[0]);
     setLastname(namestring[1]);
    
      const checkoutDetails = {
        merchantCode: "ROUTEK0001",
        redirectUrl: redirectUrl,
        amount: totalAmountAndVat,
        orderId:"RDFRT5434",
        customerId: "JKL",
        customerFirstName: firstname,
        customerSecondName: lastname,
        customerEmail: userEmail,
        customerMobile: userPhoneNumber,
     
    };

    console.log("checkout payload ",checkoutDetails);
    console.log("amount with Vat to be sent ",totalAmountAndVat)
    console.log("function amount ",amount);
    console.log("Order type ",paymentOptionsPlan)
    console.log("current url ",currentUrl)
    console.log("back url ",redirectUrl);

    try {
      const customHeaders = {
        "Content-Type": "application/json",
      };

      const response = await axios.post(
        // replace this api with the api to the getway on the backend
        "https://routemoney-storefront.deveint.live/api/v1/business/quickteller-checkout/",
        checkoutDetails,
        {
          headers: customHeaders,
        }
      );

      if (response.status >= 200 && response.status < 300) {
        console.log("response redirect url: ",response.data.responseUrl);
        // on success, consume and redirect the quickteller url for payment
        const quicktellerUrl = response.data.responseUrl;
        
        if (redirectUrl) {
        window.location.href = quicktellerUrl;
        } else {
          console.error(
            "Payment Failed. Server responded with:",
            response.status,
            response.statusText
          );
        }
      } else {
        console.error(
          "Error adding to cart. Server responded with:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }

  };

  const paymentSuceess = async (product, paymentOptionsPlan) => {
    const totalAmountAndVat = (
      parseFloat(calculateTotalAmount()) + ratings
    ).toFixed(2);

    const newProductIdQuantityMapping = [
      ...new Set(product.map((item) => item.id)),
    ].map((item) => {
      const newProduct = { product: item, quantity: getQuantityOfItem(item) };
      return newProduct;
    });

    const productDetails = {
      products: newProductIdQuantityMapping,
      total_amount: totalAmountAndVat,
      order_type: paymentOptionsPlan,
      merchant_id: allMerchantInfo.id,
    };

    try {
      const customHeaders = {
        "Content-Type": "application/json",
      };

      const response = await axios.post(
        "https://routemoney-storefront.deveint.live/api/v1/business/order/create",
        JSON.stringify(productDetails),
        {
          headers: customHeaders,
        }
      );

      if (response.status >= 200 && response.status < 300) {
        HandleTransactions(orderDetailsShareOnWhatsapp);
        navigation.navigate("SuccessScreen", {
          totalAmountPaid: totalAmountAndVat,
          brandname: "whisk-roll",
          billStatus: billStatus,
          paymentOptionsPlan: paymentOptionsPlan,
        });
        closePayModal();
        setResponseMessage(response.data.message);
      } else {
        console.error(
          "Error adding to cart. Server responded with:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  // Enable equalsplit & custom split
  const [equalSplit, setEqualSplit] = useState(false);
  const buttonsData = [
    { id: 1, name: "Dine In" },
    { id: 2, name: "Book Table" },
    { id: 3, name: "Pickup" },
    { id: 4, name: "Delivery" },
  ];
  const [paymentOptionsPlan, setPaymentOptionPlan] = useState('Dine In');
  const handlePaymentTypes = (name) => {
    name ? setPaymentOptionPlan(name) : null;
  };

  const [count, setCount] = useState(0);
  const modifyTableCapacity = (sign) => {
    if (sign === "+") {
      setCount((prev) => prev + 1);
    } else if (sign === "-") {
      count === 0
        ? alert("Maximum removal capacity reached")
        : setCount((prev) => prev - 1);
    } else {
      console.log("Formating the table capacity!!!");
    }
  };

  // Transaction function
  HandleTransactions(orderDetailsShareOnWhatsapp);

  return (
    <Layouts>
      <>
        <View style={styles.container}>
          <View style={styles.navigationLinks}>
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <ArrowLeft color="white" size={20} />
            </TouchableOpacity>
            <View style={styles.footerimagecontainer}>
              <Image
                source={IMAGES.logo}
                style={styles.qrimage}
                resizeMode="contain"
              />
            </View>
            <View></View>
          </View>

          <View style={styles.bottomContentView}>
            <View style={styles.topBillHeader}>
              {/* Equal split & Custom split */}
              {equalSplit ? (
                <Text style={styles.billTitle}>Equal split bill</Text>
              ) : (
                <Text style={styles.billTitle}>Bill</Text>
              )}
            </View>
            <View style={styles.billRecieptsection}>
              <View style={styles.cardTitle}>
                {allMerchantInfo ? (
                  <Text style={{ ...FONTS.bigsubtext, fontSize: 14 }}>
                    {allMerchantInfo.name}
                  </Text>
                ) : (
                  <ActivityIndicator />
                )}
              </View>

              <View style={styles.hoursummary}>
                <View style={styles.hourSummaryTop}>
                  {/* <Text style={styles.textClrs}>Table - 38</Text> */}
                  <Text style={styles.textClrs}>Receit No - 398</Text>
                </View>
                <Text style={styles.textClrs}>Time - 1224 Hrs</Text>
              </View>

              <View style={styles.hoursummary}>
                <View style={styles.categoriesReciept}>
                  <View style={styles.firstCartegory}>
                    <Text style={styles.itemsCartegoriesHeader}>QTY</Text>
                    <Text
                      style={[
                        styles.itemsCartegoriesHeader,
                        { marginLeft: 50 },
                      ]}
                    >
                      ITEM
                    </Text>
                  </View>
                  <Text style={styles.itemsCartegoriesHeader}>PRICE</Text>
                </View>
                {/* Render producst reports */}
                {state.items &&
                  [...new Set(state.items)].map((item, idx) => {
                    return (
                      <SingleItemReport
                        data={item}
                        key={idx}
                        getQuantityOfItem={getQuantityOfItem}
                      />
                    );
                  })}
              </View>

              <View style={[styles.hoursummary, { marginTop: 5 }]}>
                <View style={styles.subViat}>
                  <Text style={styles.viatText}>Sub total</Text>
                  <Text style={styles.viatText}>{calculateTotalAmount()}</Text>
                </View>
                <View style={styles.subViat}>
                  <Text style={styles.viatText}>VAT 16%</Text>
                  <Text style={styles.viatText}>
                    {(
                      calculateTotalAmount() -
                      (calculateTotalAmount() - calculateTotalAmount() * 0.116)
                    ).toFixed(2)}
                  </Text>
                </View>
              </View>

              <View style={[styles.hoursummaryFooter, { marginTop: 10 }]}>
                <View style={styles.subViat}>
                  <Text style={styles.viatText}>GRAND TOTAL</Text>
                  <Text style={styles.viatText}>Ksh {amountToSplit}</Text>
                </View>
                <View style={styles.subViat}>
                  {/* <Text style={styles.viatText}>Waiter - Emmanuel Miriti</Text> */}
                </View>
              </View>
            </View>
            {showPersonaStatus && (
              <View style={styles.billRecieptsection}>
                <View style={styles.billtPersonalView}>
                  <View style={styles.singlePersonBillView}>
                    <View style={{ width: 100 }}>
                      <Text style={{ ...FONTS.smallfineText, fontSize: 11 }}>
                        Emanuel Muriti
                      </Text>
                    </View>
                    <Text style={[styles.paidStatus, { color: "green" }]}>
                      Paid
                    </Text>
                    <Text style={{ ...FONTS.smallfineText, fontSize: 11 }}>
                      2000
                    </Text>
                  </View>
                  <View style={styles.singlePersonBillView}>
                    <View style={{ width: 100 }}>
                      <Text style={{ ...FONTS.smallfineText, fontSize: 11 }}>
                        Michael Douglas
                      </Text>
                    </View>
                    <Text style={[styles.paidStatus, { color: "green" }]}>
                      Paid
                    </Text>
                    <Text style={{ ...FONTS.smallfineText, fontSize: 11 }}>
                      4300
                    </Text>
                  </View>
                  <View style={styles.singlePersonBillView}>
                    <View style={{ width: 100 }}>
                      <Text style={{ ...FONTS.smallfineText, fontSize: 11 }}>
                        Emanuel Kibs
                      </Text>
                    </View>
                    <Text style={[styles.paidStatus, { color: "red" }]}>
                      Pending
                    </Text>
                    <Text style={{ ...FONTS.smallfineText, fontSize: 11 }}>
                      1203
                    </Text>
                  </View>
                </View>
              </View>
            )}

            {/* Equal split status */}
            {equalSplit && (
              <View style={styles.billRecieptsection}>
                <View style={styles.billtPersonalViewTextStatus}>
                  <Text
                    style={{
                      ...FONTS.bigsubtext,
                      fontSize: 14,
                      paddingVertical: 5,
                    }}
                  >
                    Your Bill
                  </Text>
                  <Text
                    style={{
                      ...FONTS.bigsubtext,
                      fontSize: 14,
                      paddingVertical: 5,
                    }}
                  >
                    Kes 3,414
                  </Text>
                  <Text
                    style={{
                      ...FONTS.smallfineText,
                      fontSize: 12,
                      paddingVertical: 5,
                    }}
                  >
                    each
                  </Text>
                  <Button
                    text="Pay"
                    style={[styles.button, { marginTop: 10 }]}
                  />
                </View>
              </View>
            )}
          </View>
          {showPersonaStatus ? (
            <View style={styles.bottomLinksBtns}>
              <Button
                text="Done"
                style={[styles.button, { width: "70%" }]}
                onPress={() => navigation.navigate("OfferScreen")}
              />
            </View>
          ) : billStatus ? (
            <View style={styles.bottomLinksBtns}>
              <Button text="Pay" style={styles.button} onPress={openPayModal} />
            </View>
          ) : (
            !equalSplit && (
              <View style={styles.bottomLinksBtns}>
                <Button
                  text="Split Bill"
                  style={[styles.button, { backgroundColor: "transparent" }]}
                  onPress={openSplitBillModal}
                />
                <Button
                  text="Pay"
                  style={styles.button}
                  onPress={openPayModal}
                />
              </View>
            )
          )}
        </View>

        <WebBrowserModal isVisible={isWebVisible} onClose={onWebModalClose}>
          <WebView
            style={styles.container}
            source={{ uri: "https://google.com" }}
          />
        </WebBrowserModal>

        {/* Modal */}
        <Modal
          animationType="slide"
          visible={paymentModalDisplay}
          transparent={true}
        >
          <View style={[styles.modalContainer, { alignSelf: stateLayout }]}>
            <View
              style={[
                styles.modalContent,
                paymentOptionsPlan === null && { height: hp(60) },
              ]}
            >
              <TouchableOpacity style={styles.madalPayAmount}>
                <View style={styles.barTopSectionView}></View>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.topCloseModal}
                onPress={closePayModal}
              >
                <X color="white" size={18} />
              </TouchableOpacity>
              <View style={styles.windowDisplay}>
                <View style={styles.topHeader}>
                  <Text style={{ ...FONTS.bigsubtext }}>Payment</Text>
                </View>
                <View style={styles.buttonSections}>
                  {buttonsData &&
                    buttonsData.map((item, idx) => {
                      return (
                        <TouchableOpacity
                          style={[
                            styles.btns,
                            paymentOptionsPlan === item.name && {
                              borderWidth: 1,
                              borderColor: "green",
                            },
                          ]}
                          onPress={() => handlePaymentTypes(item.name)}
                          key={idx}
                        >
                          <Text style={styles.texts}>{item.name}</Text>
                        </TouchableOpacity>
                      );
                    })}
                </View>

                <View style={styles.paymentsAmountDisplay}>
                  <View>
                    <Text style={styles.textBillSection}>
                      Bill to {allMerchantInfo.name}
                    </Text>
                    <Text style={[styles.textBillSection, { fontSize: 20 }]}>
                      Ksh{" "}
                      {(parseFloat(calculateTotalAmount()) + ratings).toFixed(
                        2
                      )}
                    </Text>
                  </View>

                  <Forms
                    id={paymentOptionsPlan}
                    modifyTableCapacity={modifyTableCapacity}
                    count={count}
                    amountToSplit={amountToSplit}
                    fullName={fullName}
                    setFullName={setFullName}
                    userEmail={userEmail}
                    setUseEmail={setUseEmail}
                    userPhoneNumber={userPhoneNumber}
                    setUserPhoneNo={setUserPhoneNo}
                    seatingPosition={seatingPosition}
                    setSeatingPosition={setSeatingPosition}
                    tableTime={tableTime}
                    setTableTime={setTableTime}
                    numberOfGuests={numberOfGuests}
                    setNumberOfGuests={setNumberOfGuests}
                    pickupTime={pickupTime}
                    setPickUpTime={setPickUpTime}
                    userAddress={userAddress}
                    setUserAddress={setUserAddress}
                  />
                </View>

                <View style={styles.paymentsAmountDisplays}>
                  <View>
                    <Text style={styles.textBillSection}>Payment methods</Text>
                  </View>
                  <View style={styles.inputsSendMethods}>
                    {/* <TouchableOpacity style={styles.singleButton} onPress={() => paymentSuceess(product, paymentOptionsPlan, (parseFloat(calculateTotalAmount()) + ratings).toFixed(2))}>
                          <View style={styles.imagePay}><Image source={IMAGES.transaction} resizeMode="contain" style={styles.imagePayIcon}/></View>
                          <Text style={{...FONTS.subsmalltext, fontSize: 10, textAlign: "center", marginTop: 5}}>Route</Text>
                        </TouchableOpacity> */}
                    <TouchableOpacity style={styles.singleButton}>
                      <View style={styles.imagePay}>
                        <Image
                          source={IMAGES.pay_route}
                          resizeMode="contain"
                          style={styles.imagePayIcon}
                        />
                      </View>
                      <Text
                        style={{
                          ...FONTS.subsmalltext,
                          fontSize: 10,
                          textAlign: "center",
                          marginTop: 5,
                        }}
                      >
                        Route
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={styles.singleButton}
                      onPress={() =>
                        paymentMpesaCard(
                          product,
                          paymentOptionsPlan,
                          (
                            parseFloat(calculateTotalAmount()) + ratings
                          ).toFixed(2)
                        )
                      }
                    >
                      {" "}
                      {/** MakePayments */}
                      <View style={styles.imagePay}>
                        <Image
                          source={IMAGES.pay_mpesa}
                          resizeMode="contain"
                          style={styles.imagePayIcon}
                        />
                      </View>
                      <Text
                        style={{
                          ...FONTS.subsmalltext,
                          fontSize: 10,
                          textAlign: "center",
                          marginTop: 5,
                        }}
                      >
                        Mpesa
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={styles.singleButton}
                      onPress={() =>
                        paymentMpesaCard(
                          product,
                          paymentOptionsPlan,
                          (
                            parseFloat(calculateTotalAmount()) + ratings
                          ).toFixed(2)
                        )
                      }
                    >
                      {" "}
                      {/** MakePayments */}
                      <View style={styles.imagePay}>
                        <Image
                          source={IMAGES.pay_card}
                          resizeMode="contain"
                          style={styles.imagePayIcon}
                        />
                      </View>
                      <Text
                        style={{
                          ...FONTS.subsmalltext,
                          fontSize: 10,
                          textAlign: "center",
                          marginTop: 5,
                        }}
                      >
                        Card
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={styles.singleButton}
                      onPress={() =>
                        paymentOnDelivery(
                          product,
                          (
                            parseFloat(calculateTotalAmount()) + ratings
                          ).toFixed(2)
                        )
                      }
                    >
                      <View style={styles.imagePay}>
                        <Image
                          source={IMAGES.pay_delivery}
                          resizeMode="contain"
                          style={styles.imagePayIcon}
                        />
                      </View>
                      <Text
                        style={{
                          ...FONTS.subsmalltext,
                          fontSize: 10,
                          textAlign: "center",
                          marginTop: 5,
                        }}
                      >
                        Pay On Delivery
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Modal>

        {/* Split modal */}
        <Modal
          animationType="slide"
          visible={splitBillModal}
          transparent={true}
        >
          <View style={[styles.modalContainer, { alignSelf: stateLayout }]}>
            <View style={styles.modalContainerGroupPayment}>
              <TouchableOpacity style={styles.madalPayAmount}>
                <View style={styles.barTopSectionView}></View>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.topCloseModal}
                onPress={openSplitBillModal}
              >
                <X color="white" size={18} />
              </TouchableOpacity>
              <View style={styles.groupTitle}>
                <Text
                  style={{
                    ...FONTS.subsmalltext,
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Select An option
                </Text>
              </View>

              <View style={styles.modalButtonOrderGroupChart}>
                <Button
                  text="Split Equally"
                  style={styles.buttonGroup}
                  onPress={() => openCustomizePeopleToSplitModal(amountToSplit)}
                />
                <Button
                  text="Pay Custom Amount"
                  style={[
                    styles.buttonGroup,
                    {
                      backgroundColor: "transparent",
                      borderWidth: 1,
                      borderColor: COLORS.blue,
                    },
                  ]}
                  onPress={openPayModal}
                />
              </View>
            </View>
          </View>
        </Modal>

        {/* Eqaul split customized personals */}
        <Modal animationType="slide" visible={sharePlitLink} transparent={true}>
          <View style={[styles.modalContainer, { alignSelf: stateLayout }]}>
            <View style={styles.madalCustomizePeopleSplit}>
              <TouchableOpacity style={styles.madalPayAmount}>
                <View style={styles.barTopSectionView}></View>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.topCloseModal}
                onPress={openCustomizePeopleToSplitModal}
              >
                <X color="white" size={18} />
              </TouchableOpacity>

              <View style={styles.modalButtonOrderGroupChart}>
                <View style={styles.peopleCustomSection}>
                  <Text style={{ ...FONTS.smallsubtext, fontSize: 11 }}>
                    Total People on The Table
                  </Text>
                  <View style={styles.incrementDecrementPeople}>
                    <TouchableOpacity
                      style={styles.peopleBtns}
                      onPress={() =>
                        customizePeopleAmPayingFor({ sign: "-", id: "table" })
                      }
                    >
                      <Text style={styles.colorsBtns}>-</Text>
                    </TouchableOpacity>
                    <Text style={styles.numberOfPeople}>{ontableCount}</Text>
                    <TouchableOpacity
                      style={styles.peopleBtns}
                      onPress={() =>
                        customizePeopleAmPayingFor({ sign: "+", id: "table" })
                      }
                    >
                      <Text style={styles.colorsBtns}>+</Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={styles.peopleCustomSection}>
                  <Text style={{ ...FONTS.smallsubtext, fontSize: 11 }}>
                    People I'm Paying For
                  </Text>
                  <View style={styles.incrementDecrementPeople}>
                    <TouchableOpacity
                      style={styles.peopleBtns}
                      onPress={() =>
                        customizePeopleAmPayingFor({ sign: "-", id: "paying" })
                      }
                    >
                      <Text style={styles.colorsBtns}>-</Text>
                    </TouchableOpacity>
                    <Text style={styles.numberOfPeople}>
                      {peopleAmPayingFor}
                    </Text>
                    <TouchableOpacity
                      style={styles.peopleBtns}
                      onPress={() =>
                        customizePeopleAmPayingFor({ sign: "+", id: "paying" })
                      }
                    >
                      <Text style={styles.colorsBtns}>+</Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <Button
                  text="Share Link"
                  style={styles.buttonGroup}
                  onPress={openMediaModalToShareLinks}
                />
              </View>
            </View>
          </View>
        </Modal>

        {/* Eqaul split model for the receipt screen */}
        <Modal animationType="slide" visible={false} transparent={true}>
          <View style={[styles.modalContainer, { alignSelf: stateLayout }]}>
            <View style={styles.modalContentEqualSplit}>
              <TouchableOpacity style={styles.madalPayAmount}>
                <View style={styles.barTopSectionView}></View>
              </TouchableOpacity>
              <TouchableOpacity style={styles.topCloseModal}>
                <X color="white" size={18} />
              </TouchableOpacity>
              <View style={styles.groupTitle}>
                <Text
                  style={{
                    ...FONTS.subsmalltext,
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Enter your details
                </Text>
              </View>
              <View style={styles.windowDisplay}>
                <TextInput
                  type="text"
                  placeholder="Enter name"
                  style={styles.singleInput}
                />
                <TextInput
                  type="phone"
                  placeholder="Enter phone number"
                  style={styles.singleInput}
                />
                <TextInput
                  type="email"
                  placeholder="Enter email"
                  style={styles.singleInput}
                />
              </View>
              <View style={styles.modalButtonOrder}>
                <Button text="Pay" style={styles.button} />
              </View>
            </View>
          </View>
        </Modal>

        {/* Fetch user dataa if not available */}
        {/* Modal */}
      </>
    </Layouts>
  );
}

{
  /* webview Modal */
}
const WebBrowserModal = ({ isVisible, children, onClose }) => {
  return (
    <Modal animationType="slide" transparent={true} visible={isVisible}>
      <ScrollView style={styles.modalContent}>
        <View style={styles.titleContainer}>
          <Pressable onPress={onClose}>
            <X stroke="white" fill="transparent" width={16} height={16} />
          </Pressable>
        </View>
        {children}
      </ScrollView>
    </Modal>
  );
};

const { width, height } = Dimensions.get("screen");
const styles = StyleSheet.create({
  // Modal styles
  customerModalContainer: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "relative",
    width: wp(100),
    height: hp(100),
    maxWidth: 430,
  },
  customerModalContent: {
    backgroundColor: COLORS.backgroundDark,
    width: "100%",
    height: hp(50),
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    elevation: 5,
  },
  paymentsInputs: {
    marginTop: 10,
    padding: 10,
    borderRadius: 10,
    backgroundColor: COLORS.backgroundLight,
    marginHorizontal: 4,
    color: COLORS.gray,
    border: "none",
    width: wp(90),
  },
  //
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.backgroundDark,
    position: "relative",
    maxWidth: 430,
  },
  navigationLinks: {
    width: "100%",
    position: "absolute",
    top: 0,
    paddingHorizontal: 5,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    paddingTop: 10,
  },

  bottomContentView: {
    width: "100%",
    paddingHorizontal: 10,
  },
  bottomLinksBtns: {
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    flexDirection: "row",
    bottom: 10,
    left: 0,
    right: 0,
    maxWidth: 430,
  },
  button: {
    borderWidth: 1,
    borderColor: COLORS.blue,
    marginVertical: 10,
    marginHorizontal: 10,
    width: "36%",
    fontSize: 14,
  },
  footerimagecontainer: {
    width: 160,
    height: 50,
    justifyContent: "center",
    alignItems: "flex-start",
    borderRadius: 5,
    marginVertical: 20,
  },
  qrimage: {
    width: "100%",
    height: "100%",
  },

  bottomContentView: {
    width: "100%",
    height: "70%",
    maxHeight: "70%",
    paddingHorizontal: 10,
  },
  topBillHeader: {
    paddingVertical: 10,
  },
  billTitle: {
    ...FONTS.bigsubtext,
    fontSize: 14,
  },
  billRecieptsection: {
    backgroundColor: COLORS.backgroundLight,
    width: "100%",
    padding: 10,
    borderRadius: 21,
    marginVertical: 10,
  },
  cardTitle: {
    width: "100%",
    paddingVertical: 15,
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: COLORS.gray,
  },
  hoursummary: {
    borderBottomWidth: 1,
    borderBottomColor: COLORS.gray,
    paddingVertical: 5,
  },
  hoursummaryFooter: {
    paddingVertical: 5,
  },
  hourSummaryTop: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  textClrs: {
    ...FONTS.fineText,
    fontSize: 11,
    paddingVertical: 2,
  },
  categoriesReciept: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  firstCartegory: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  itemsCartegoriesHeader: {
    ...FONTS.bigsubtext,
    fontSize: 10,
  },
  categoriesRecieptValue: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 5,
  },
  firstCartegoryValue: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  itemsCartegoriesHeaderValue: {
    ...FONTS.bigsubtext,
    fontSize: 10,
  },
  subViat: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 5,
  },
  viatText: {
    ...FONTS.bigsubtext,
    fontSize: 10,
  },

  // Modal styles
  modalContainer: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    paddingTop: 5,
    width: wp(100),
    height: hp(100),
    maxWidth: 430,
  },

  modalContent: {
    backgroundColor: COLORS.backgroundDark,
    width: "100%",
    height: "95%",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    elevation: 5,
    position: "absolute",
    bottom: 0,
    paddingHorizontal: 5,
  },

  madalPayAmount: {
    width: "100%",
    height: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  modelTextColor: {
    paddingVertical: 5,
    width: "100%",
    textAlign: "center",
    ...FONTS.bigsubtext,
  },
  barTopSectionView: {
    borderTopWidth: 3,
    height: 1,
    borderTopColor: "white",
    width: 52,
    borderRadius: 100,
    marginBottom: 5,
    marginTop: -20,
  },
  topCloseModal: {
    width: "100%",
    paddingHorizontal: 5,
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
  },
  windowDisplay: {
    width: "100%",
    flex: 1,
    paddingVertical: 5,
  },
  topHeader: {
    justifyContent: "center",
    alignItems: "center",
  },
  buttonSections: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 1,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.gray,
    paddingVertical: 15,
    paddingHorizontal: 5,
  },
  btns: {
    paddingHorizontal: 2,
    paddingVertical: 10,
    backgroundColor: COLORS.backgroundLight,
    borderRadius: 10,
    width: 70,
    marginHorizontal: 3,
    justifyContent: "center",
    alignItems: "center",
  },
  texts: {
    ...FONTS.fineText,
    fontSize: 10,
  },
  paymentsAmountDisplay: {
    borderBottomWidth: 1,
    borderBottomColor: COLORS.gray,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  textBillSection: {
    ...FONTS.smalltabText,
    paddingVertical: 5,
  },
  inputs: {
    marginTop: 5,
    maxWidth: 430,
  },
  inputsSendMethods: {
    marginTop: 10,
    maxWidth: 430,
    backgroundColor: COLORS.backgroundLight,
    paddingHorizontal: 10,
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingVertical: 10,
  },
  paymentsAmountDisplays: {
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  singleButton: {
    width: 60,
    height: 80,
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: 5,
  },
  imagePay: {
    width: 30,
    height: 30,
    borderRadius: 100,
    backgroundColor: COLORS.gray,
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
  },
  imagePayIcon: {
    width: "100%",
    height: "100%",
  },
  billtPersonalView: {
    paddingHorizontal: 15,
  },
  billtPersonalViewTextStatus: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  singlePersonBillView: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginVertical: 5,
  },
  paidStatus: {
    backgroundColor: COLORS.white,
    paddingVertical: 2,
    paddingHorizontal: 5,
    borderRadius: 100,
    fontSize: 9,
    width: 58,
    textAlign: "center",
  },

  // Split bill modal
  modalContainerGroupPayment: {
    backgroundColor: COLORS.backgroundDark,
    padding: 20,
    width: "100%",
    height: "35%",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    elevation: 5,
  },
  modalButtonOrderGroupChart: {
    marginVertical: 10,
  },
  buttonGroup: {
    width: "96%",
    marginVertical: 10,
    fontSize: 14,
  },
  groupTitle: {
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 5,
  },

  // Customize people split
  madalCustomizePeopleSplit: {
    backgroundColor: COLORS.backgroundDark,
    padding: 20,
    width: "100%",
    height: "45%",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    elevation: 5,
  },
  peopleCustomSection: {
    width: "100%",
    paddingVertical: 15,
    paddingHorizontal: 15,
    backgroundColor: COLORS.backgroundLight,
    borderRadius: 10,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginVertical: 8,
  },
  incrementDecrementPeople: {
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
  },
  numberOfPeople: {
    marginHorizontal: 5,
    ...FONTS.smallfineText,
    fontSize: 13,
  },
  peopleBtns: {
    width: 15,
    height: 15,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: COLORS.white,
    justifyContent: "center",
    alignItems: "center",
  },
  colorsBtns: {
    textAlign: "center",
    ...FONTS.smallfineText,
    fontSize: 11,
  },

  // Equal split amount model
  modalContentEqualSplit: {
    backgroundColor: COLORS.backgroundDark,
    width: "100%",
    height: "50%",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    elevation: 5,
    position: "absolute",
    bottom: 0,
    paddingHorizontal: 20,
    paddingVertical: 20,
  },

  windowDisplay: {
    width: "100%",
    flex: 1,
    paddingVertical: 5,
  },
  groupTitle: {
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 5,
  },
  modalButtonOrder: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginBottom: 40,
    maxWidth: 430,
  },
  modalButtonOrderGroupChart: {
    marginVertical: 10,
  },
  singleInput: {
    padding: 10,
    borderRadius: 10,
    backgroundColor: COLORS.backgroundLight,
    marginVertical: 5,
    color: COLORS.gray,
    border: "none",
  },
});
export default RecieptScreen;
