import {
  createContext,
  useContext,
  useReducer,
  useState,
  useEffect,
} from "react";
import { COLORS, IMAGES } from "../constants";
import axios from "axios";
import { useMerchantStore } from "../store/merchant_store";

const CartContext = createContext();
// Mock data
const itemdata = [
  {
    id: 1,
    name: "White Cap",
    price: 2500,
    image: IMAGES.fullfood,
    background: "yellow",
    category_name: "Fruits",
    description:
      "A rich stew prepared with eggplants cooked together with jalepenos. A richprepared shields",
  },
  {
    id: 2,
    name: "Mixed salad vegetables",
    price: 7000,
    image: IMAGES.fullfood,
    background: "yellow",
    category_name: "Food",
    description:
      "A rich stew prepared with eggplants cooked together with jalepenos. A richprepared shields",
  },
  {
    id: 3,
    name: "White Coffee",
    price: 1000,
    image: IMAGES.bottle1,
    background: "yellow",
    category_name: "Food",
    description:
      "A rich stew prepared with eggplants cooked together with jalepenos. A richprepared shields",
    percentage: 2,
  },
  {
    id: 4,
    name: "Black Coffee",
    price: 3500,
    image: IMAGES.bottle1,
    background: "yellow",
    category_name: "Drinks",
    description:
      "A rich stew prepared with eggplants cooked together with jalepenos. A richprepared shields",
  },
  {
    id: 5,
    name: "Black Coffee",
    price: 3500,
    image: IMAGES.bottle1,
    background: "yellow",
    category_name: "Offers",
    description:
      "A rich stew prepared with eggplants cooked together with jalepenos. A richprepared shields",
  },
  {
    id: 6,
    name: "Black Coffee",
    price: 3500,
    image: IMAGES.bottle1,
    background: "yellow",
    category_name: "Data",
    description:
      "A rich stew prepared with eggplants cooked together with jalepenos. A richprepared shields",
  },
  {
    id: 7,
    name: "Black Coffee",
    price: 3500,
    image: IMAGES.bottle1,
    background: "yellow",
    category_name: "Harzards",
    description:
      "A rich stew prepared with eggplants cooked together with jalepenos. A richprepared shields",
  },
  {
    id: 8,
    name: "White Coffee",
    price: 1000,
    image: IMAGES.bottle1,
    background: "yellow",
    category_name: "Casino",
    description:
      "A rich stew prepared with eggplants cooked together with jalepenos. A richprepared shields",
    percentage: 2,
  },
  {
    id: 9,
    name: "Black Coffee",
    price: 3500,
    image: IMAGES.bottle1,
    background: "Transfer",
    category_name: "Offers",
    description:
      "A rich stew prepared with eggplants cooked together with jalepenos. A richprepared shields",
  },
  {
    id: 10,
    name: "White Coffee",
    price: 1000,
    image: IMAGES.bottle1,
    background: "yellow",
    category_name: "Alarm",
    description:
      "A rich stew prepared with eggplants cooked together with jalepenos. A richprepared shields",
    percentage: 2,
  },
];

const initialState = {
  items: [],
};

const cartReducer = (state, action) => {
  let updatedItems;

  switch (action.type) {
    case "ADD_TO_CART":
      updatedItems = [...state.items, action.payload];
      updateLocalStorage(updatedItems);
      return { ...state, items: updatedItems };

    case "REMOVE_FROM_CART":
      const itemIdToRemove = action.payload.id;
      const lastAddedIndex = state.items
        .slice()
        .reverse()
        .findIndex((item) => item.id === itemIdToRemove);
      if (lastAddedIndex !== -1) {
        updatedItems = [...state.items];
        updatedItems.splice(updatedItems.length - 1 - lastAddedIndex, 1);
        updateLocalStorage(updatedItems);
        return { ...state, items: updatedItems };
      }
      return state;

    case "CLEAR_CART":
      updateLocalStorage([]);
      return { ...state, items: [] };

    default:
      return state;
  }
};

const updateLocalStorage = (items) => {
  localStorage.setItem("cartItems", JSON.stringify(items));
};

const merchantData = JSON.parse(localStorage.getItem("merchantData"));

// Main context
const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);
  const [products, setProducts] = useState([]); //itemdata
  const [totalCart, setTotalCart] = useState(0);
  const [loading, setLoading] = useState(true); // false && dummy data
  // console.log("State products updated api: ", state.items)
  // console.log("Products fetch api: ", products)
  const merchantStore = useMerchantStore((state) => state.merchant);
  const [stateLayout, setStateLayout] = useState("flex-start");
  const changeLayout = (data) => setStateLayout(data);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      console.log("local storage: ", merchantData);
      const response = await axios.get(
        `https://backoffice.route.money/api/v1/business/merchant/products/${merchantData.slug}`
      );
      setProducts(response.data.results);
      setLoading(false);
   
    } catch (error) {
      console.error("Failed to fetch user single business data:", error);
    }
  };

  // useEffect(() => {
  //   fetch('https://backoffice.route.money/api/v1/business/products/list')
  //     .then(response => response.json())
  //     .then(data => {
  //       setProducts(data.results);
  //       setLoading(false);
  //     })
  //     .catch(error => console.error('Error fetching products:', error));
  // }, []);

  const calculateTotalAmount = () => {
    const totalAmount = state.items
      .filter((item) => typeof item.price === "string")
      .map((item) => parseFloat(item.price))
      .reduce((sum, element) => sum + element, 0);
    return totalAmount.toFixed(2);
  };

  const getNumberOfItems = () => {
    return state.items.length;
  };

  const getItemDetails = () => {
    const itemDetails = {};
    state.items.forEach((item) => {
      if (itemDetails[item.id]) {
        itemDetails[item.id].quantity += 1;
      } else {
        itemDetails[item.id] = {
          id: item.id,
          name: item.name,
          price: item.price,
          quantity: 1,
        };
      }
    });
    return Object.values(itemDetails);
  };

  const getPriceOfItem = (state, itemId) => {
    const item = state.items.find((item) => item.id === itemId);
    return item ? item.price : null;
  };

  const getQuantityOfItem = (itemId) => {
    const item = state.items.filter((item) => item.id === itemId);
    return item.length;
  };

  useEffect(() => {
    setTotalCart(getNumberOfItems());
  }, [state]);

  useEffect(() => {
    const storedItems = localStorage.getItem("cartItems");
    if (storedItems) {
      dispatch({ type: "SET_CART", payload: JSON.parse(storedItems) });
    }
  }, []);

  const [deviceFrame, setDeviceFrame] = useState(false);
  const includeDeviceFrmae = () => setDeviceFrame(!deviceFrame);

  return (
    <CartContext.Provider
      value={{
        state,
        dispatch,
        calculateTotalAmount,
        getNumberOfItems,
        getItemDetails,
        getPriceOfItem,
        products,
        getQuantityOfItem,
        totalCart,
        loading,
        stateLayout,
        changeLayout,
        includeDeviceFrmae,
        deviceFrame,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

export { CartProvider, useCart };
