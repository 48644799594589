import { COLORS, IMAGES, FONTS} from "../../../../constants/index";
import { StyleSheet, View, Text, Dimensions, TouchableOpacity, Image, Alert, Modal, ScrollView } from "react-native";
import React, { useState } from "react";
import { X } from 'lucide-react';
import Button from "../../../../components/Button";
import { useCart } from "../../../../context/SinglecartContext";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import { useGroupCart } from "../../../../context/GroupmanagerContext";

function Offers({ navigation, data, groupno, memberId }) {
  const { dispatch, getQuantityOfItem, stateLayout } = useCart();
  const {addToCart, removeFromCart, state} = useGroupCart();
  
  const [offermodal, setOffermodal] = useState(false)

  const openModalFilter = () => {
    setOffermodal(true)
  }
  const closeTheModal = () => {
    setOffermodal(false)
  }

  const bringToTable = async (product) => { 
    const finalProduct = { ...product, orderType: "table" }; 
    if(groupno && memberId ) {
      addToCart(groupno, memberId, finalProduct)
    }else{
      dispatch({ type: 'ADD_TO_CART', payload: finalProduct });
    }
  };
  const addItemToCart = (product) => {
    if(groupno && memberId ) {description
      addToCart(groupno, memberId, product);
    }else{
      dispatch({ type: 'ADD_TO_CART', payload: product })
    }
  }
  const generalRemoveFromCart = (product) => {
    if(groupno && memberId ) {
      removeFromCart(groupno, memberId, product)
    }else{
      dispatch({ type: 'REMOVE_FROM_CART', payload: product });
    }
  }

  return (
    <>
     <TouchableOpacity style={styles.singleOfferView} onPress={openModalFilter}>
        <View style={{flexDirection: "row", alignItems: "center"}}>
            <View style={[styles.singleOfferImg, {backgroundColor: "white"}]}><Image source={data.image} style={styles.singleOfferTagImg}/></View>
            <View style={styles.offertextsDesc}>
            <View style={{flexDirection: "row", alignItems: "center"}}> 
                <Text style={[styles.amountOffer, {fontSize: 16}]}>{data.name}</Text>
            </View>
            <View style={{flexDirection: "column", alignItems: "center"}}>
                 <Text style={[styles.amountOffer, {fontWeight: 700, textDecorationLine: 'line-through', color: "orange", fontSize: 10,}]}>Was Ksh. {data.price}</Text>
                 <View style={{flexDirection: "row", alignItems: "center"}}>
                    <Text style={[styles.amountOffer, {fontWeight: 700, color: "greenyellow"}]}>Now Ksh. {data.price - Math.floor((data.discount_percentage / 100) * data.price)}</Text>
                 </View>
            </View>
            </View>
        </View>
        <View style={styles.handleChnageCart}>
            <Text style={{marginLeft: 10, color: "skyblue", fontSize: 12, marginBottom: 15}}>Save Ksh. {Math.floor((data.discount_percentage / 100) * data.price)}</Text>
             <View style={{flexDirection: "row", alignItems: "center"}}>
                <TouchableOpacity style={styles.cartBtnsOutline} onPress={() => generalRemoveFromCart(data)}><Text style={styles.actions}>-</Text></TouchableOpacity>
                <Text style={styles.cartDisplayCapacity}>{getQuantityOfItem(data.id)}</Text>
                <TouchableOpacity style={styles.cartBtnsOutline} onPress={() => addItemToCart(data)}><Text style={styles.actions}>+</Text></TouchableOpacity>
            </View>
        </View> 
     </TouchableOpacity>

    {/* Modal */}
     <Modal
        animationType="slide"
        visible={offermodal}
        transparent={true}
     >
        <View style={[styles.modalContainer, {alignSelf: stateLayout}]}>
            <View style={styles.modalContent}>
                <TouchableOpacity style={styles.madalPayAmount}>
                    <View style={styles.barTopSectionView}></View>
                </TouchableOpacity>
                <TouchableOpacity style={styles.topCloseModal} onPress={closeTheModal}><X color="white" size={18}/></TouchableOpacity>
                {/* Modal content */}
                <View style={styles.windowDisplay}>
                    <View style={styles.imageModalContainer}>
                         <Image source={data.image} style={styles.singleOfferTagImgModal} resizeMode="stretch"/>
                    </View>
                    <ScrollView style={{flex: 1}}
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}
                    >
                        <View style={styles.textContainer}>
                            <Text style={[styles.textMain, {fontWeight: 700, paddingVertical: 5}]}>{data.name}</Text>
                            <Text style={styles.textMain}>{data.description} </Text>
                            <View style={{flexDirection: "row", alignItems: "center"}}>
                                <Text style={[styles.amountOffer, {fontWeight: 700, textDecorationLine: 'line-through', color: "orange"}]}>Was Ksh. {data.price}</Text>
                                <View style={{flexDirection: "row", alignItems: "center", marginLeft: 15}}>
                                    <Text style={[styles.amountOffer, {fontWeight: 700, color: "greenyellow", marginLeft: 5}]}>Now Ksh. {data.price - Math.floor((data.discount_percentage / 100)* data.price)}</Text>
                                    <Text style={{marginLeft: 10, color: "skyblue", fontSize: 12}}>Save Ksh. {Math.floor((data.discount_percentage / 100)* data.price)}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.modalActionButton}>
                            <View style={{flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                <TouchableOpacity style={styles.cartBtnsOutline} onPress={() => generalRemoveFromCart(data)}>
                                    <Text style={styles.actions}>-</Text>
                                </TouchableOpacity>
                                <Text style={styles.cartDisplayCapacity}>{getQuantityOfItem(data.id)}</Text>
                                <TouchableOpacity style={styles.cartBtnsOutline} onPress={() => addItemToCart(data)}>
                                    <Text style={styles.actions}>+</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={styles.modalButtonOrder}>
                            <Button text="Bring to Table" style={styles.button} onPress={() => bringToTable(data)}/>
                        </View>
                    </ScrollView>
                </View>
            </View>
        </View>
     </Modal>
    </>
  );
}

const styles = StyleSheet.create({
  singleOfferView: {
    marginTop: 2,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: COLORS.backgroundLight,
    borderRadius: 10,
    height: 83,
    width: "100%",
    paddingHorizontal: 5,
    marginBottom: 10,
  },
  singleOfferImg: {
    height: hp(9),
    width: 70,
    borderRadius: 10,
    overflow: "hidden",
  },
  singleOfferTagImg: {
    width: "100%",
    height: "100%",
    borderRadius: 10,
  },
  offertextsDesc: {
    marginLeft: 15,
  },
  amountOffer: {
    ...FONTS.fineText,
    fontSize: 14,
    paddingVertical: 3,
  },
  handleChnageCart: {
    justifyContent: "center",
    alignItems: "center",
    marginRight: 5,
  },
  cartBtnsOutline: {
    width: 20,
    height: 20,
    textAlign: "center",
    borderRadius: 100,
    borderWidth: 1,
    borderColor: COLORS.white,
    justifyContent: "center",
    alignItems: "center",
  },
  actions: {
    ...FONTS.bigsubtext,
    fontSize: 11,
  },
  cartDisplayCapacity: {
    marginHorizontal: 10,
    ...FONTS.fineText,
  },
  
  // Modal styles
  modalContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    paddingTop: 5,
    width: wp(100),
    height: hp(100),
    maxWidth: 430,
  },
  modalContent: {
    backgroundColor: COLORS.backgroundDark,
    padding: 20,
    width: '100%', 
    height: hp(70),
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    elevation: 5,
  },
  madalPayAmount: {
    width: "100%",
    height: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  modelTextColor: {
    paddingVertical: 5,
    width: "100%",
    textAlign: "center",
    ...FONTS.bigsubtext,
  },
  barTopSectionView: {
    borderTopWidth: 3,
    height: 1,
    borderTopColor: "white",
    width: 52,
    borderRadius: 100,
    marginBottom: 5,
    marginTop: -20,
  },
  topCloseModal: {
    width: "100%",
    paddingHorizontal: 5,
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'row',
  },
  button: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: COLORS.blue,
    marginVertical: 10,
    width: "90%",
  },
  windowDisplay: {
    width: "100%",
    flex: 1,
    paddingVertical: 5,
  },
  imageModalContainer: {
    width: "100%",
    height: 210,
    borderRadius: 10,
    overflow: "hidden",
    backgroundColor: "white",
    marginVertical: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  singleOfferTagImgModal: {
    width: "100%",
    height: "100%",
  },
  textContainer: {
    width: "100%",
    paddingVertical: 5,
    paddingHorizontal: 10,
    backgroundColor: COLORS.backgroundLight,
    marginVertical: 10,
    borderRadius: 10,
  },
  textMain: {
    ...FONTS.smallfineText,
    fontSize: 14,
  },
  modalActionButton: {
    marginVertical: 10,
  },
  modalButtonOrder: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: -10,
  }
});
export default Offers;
